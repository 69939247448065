import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackendService, CatalogItem, LangItem } from 'src/app/services/backend.service';

@Component({
    selector: 'app-select-catalog',
    templateUrl: './select-catalog.component.html',
    styleUrls: ['./select-catalog.component.scss'],
})
export class SelectCatalogComponent implements OnInit {
    catalogs: CatalogItem[] = [];
    selectedCatalog?: CatalogItem;
    selectedLang?: LangItem;
    isLoading = false;
    emptyCatalog: CatalogItem = { lang: [], name: '', vkorg: '', customErpTypes: [] };

    constructor(
        private backend: BackendService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            vkorg: string;
            language: string;
        },
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.backend.getCatalog().subscribe((result) => {
            this.catalogs = result;
            this.selectedCatalog = this.catalogs.find((c) => c.vkorg === this.data.vkorg);
            this.selectedLang = this.selectedCatalog?.lang.find((l) => l.id === this.data.language);
            this.isLoading = false;
        });
    }

    catalogChanged() {
        this.selectedLang = this.selectedCatalog?.lang.length === 1 ? this.selectedCatalog.lang[0] : undefined;
    }
}
