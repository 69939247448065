import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { MenuService } from '../services/menu.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    authenticated$: Observable<boolean>;

    constructor(
        public auth: AuthService,
        private menuService: MenuService,
    ) {
        this.authenticated$ = this.auth.csrfToken$.pipe(
            delay(10),
            map((token) => {
                return !!token;
            }),
        );
    }

    menuClick() {
        this.menuService.toggle();
    }
}
