import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class HttpAuthInterceptorService implements HttpInterceptor {
    private apiUrls: string[];

    constructor(private auth: AuthService) {
        this.apiUrls = [new URL(environment.apiUrl).host, new URL(environment.everestApiUrl).host];
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (req.url.includes('saml/csrf') || !this.apiUrls.some((url) => req.url.includes(url))) {
            return next.handle(req);
        }

        return this.auth.csrfToken$.pipe(
            first((val) => val !== undefined),
            switchMap((value) =>
                next.handle(req.clone({ headers: req.headers.set('Authorization', `CSRF ${value}`) })).pipe(
                    catchError((e) => {
                        if (e instanceof HttpErrorResponse && e.status === 401) {
                            // refresh CSRF token and retry active request if fetching of token succeed
                            return this.auth.fetchNewCsrfToken().pipe(switchMap(() => this.intercept(req, next)));
                        }
                        throw e;
                    }),
                ),
            ),
        );
    }
}
