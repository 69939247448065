import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SalesReceipt } from '../../../services/backend.service';

@Component({
    selector: 'app-sales-receipt-results',
    templateUrl: './sales-receipt-results.component.html',
    styleUrls: ['./sales-receipt-results.component.scss'],
})
export class SalesReceiptResultsComponent implements OnChanges {
    displayedColumns = ['checkbox', 'id', 'description'];
    addingCallInProgress = false;
    showNotRelevant = false;

    selection = new SelectionModel<SalesReceipt>(true, []);
    relevant: SalesReceipt[] = [];
    notRelevant: SalesReceipt[] = [];

    @Input()
    dataSource: SalesReceipt[] = [];

    @Output() addProducts = new EventEmitter<SalesReceipt[]>();

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dataSource.currentValue !== changes.dataSource.previousValue) {
            this.relevant = changes.dataSource.currentValue.filter((i: SalesReceipt) => i.type.id === '1');
            this.notRelevant = changes.dataSource.currentValue.filter((i: SalesReceipt) => i.type.id !== '1');
            this.selection.clear();
            this.selection.select(...this.relevant);
            this.setRelevant(false);
        }
    }

    setRelevant(setTo: boolean) {
        this.showNotRelevant = setTo;
        if (this.showNotRelevant) {
            this.dataSource = [...this.relevant, ...this.notRelevant];
        } else {
            this.dataSource = [...this.relevant];
        }
    }

    relevantToggle() {
        this.setRelevant(!this.showNotRelevant);
    }

    isAllSelected() {
        return this.selection.selected.length === this.relevant.length;
    }

    masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this.selection.select(...this.relevant);
    }

    addToProject() {
        this.addingCallInProgress = true;
        this.addProducts.emit(this.selection.selected);
    }
}
