<h2 mat-dialog-title>{{ 'create_product.title' | translate }}</h2>
<mat-dialog-content>
    <form *ngIf="mainFormGroup">
        <table class="full-width" [formGroup]="mainFormGroup">
            <tr>
                <td>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'create_product.description' | translate }}</mat-label>
                        <input type="text" matInput formControlName="productDescriptionControl" required />
                        <mat-error *ngIf="mainFormGroup.get('productDescriptionControl')?.hasError('required')">
                            {{ 'errors.field_is_required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'create_product.category' | translate }}</mat-label>
                        <mat-select formControlName="productCategoryControl" name="category" required>
                            <mat-option *ngFor="let productCategory of productCategories" [value]="productCategory">
                                {{ productCategory.viewValue }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="mainFormGroup.get('productCategoryControl')?.hasError('required')">
                            {{ 'errors.field_is_required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </td>
            </tr>
        </table>

        <div
            *ngIf="mainFormGroup.get('productCategoryControl')?.value.value === 'controlUnitAccessories'"
            [formGroup]="controlUnitAccessoriesFormGroup"
        >
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'create_product.temperatureControllerClassification' | translate }}</mat-label>
                <mat-select
                    formControlName="temperatureControllerClassificationControl"
                    name="temperatureControllerClassification"
                    required
                >
                    <mat-option
                        *ngFor="let classificationOfTemperatureController of classificationsOfTemperatureControllers"
                        [value]="classificationOfTemperatureController.value"
                    >
                        {{ classificationOfTemperatureController.viewValue }}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="
                        controlUnitAccessoriesFormGroup
                            .get('temperatureControllerClassificationControl')
                            ?.hasError('required')
                    "
                >
                    {{ 'errors.field_is_required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <div
            *ngIf="mainFormGroup.get('productCategoryControl')?.value.value === 'heatPump'"
            [formGroup]="heatPumpFormGroup"
        >
            <table class="full-width">
                <tr>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{
                                'create_product.seasonalSpaceHeatingEnergyEfficiency' | translate
                            }}</mat-label>
                            <input
                                type="number"
                                matInput
                                formControlName="seasonalSpaceHeatingEnergyEfficiencyControl"
                                required
                            />
                            <span matTextSuffix>{{ 'create_product.percent' | translate }}</span>
                            <mat-error
                                *ngIf="
                                    heatPumpFormGroup
                                        .get('seasonalSpaceHeatingEnergyEfficiencyControl')
                                        ?.hasError('required')
                                "
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'create_product.nominalHeatOutput' | translate }}</mat-label>
                            <input type="number" matInput formControlName="nominalHeatOutputControl" required />
                            <span matTextSuffix>{{ 'create_product.w' | translate }}</span>
                            <mat-error *ngIf="heatPumpFormGroup.get('nominalHeatOutputControl')?.hasError('required')">
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                </tr>

                <tr>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{
                                'create_product.temperatureControllerClassification' | translate
                            }}</mat-label>
                            <mat-select
                                formControlName="temperatureControllerClassificationControl"
                                name="temperatureControllerClassification"
                                required
                            >
                                <mat-option
                                    *ngFor="
                                        let classificationOfTemperatureController of classificationsOfTemperatureControllers
                                    "
                                    [value]="classificationOfTemperatureController.value"
                                >
                                    {{ classificationOfTemperatureController.viewValue }}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="
                                    heatPumpFormGroup
                                        .get('temperatureControllerClassificationControl')
                                        ?.hasError('required')
                                "
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                </tr>
            </table>
        </div>

        <div
            *ngIf="mainFormGroup.get('productCategoryControl')?.value.value === 'heatingBoiler'"
            [formGroup]="heatingBoilerFormGroup"
        >
            <table class="full-width">
                <tr>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{
                                'create_product.seasonalSpaceHeatingEnergyEfficiency' | translate
                            }}</mat-label>
                            <input
                                type="number"
                                matInput
                                formControlName="seasonalSpaceHeatingEnergyEfficiencyControl"
                                required
                            />
                            <span matTextSuffix>{{ 'create_product.percent' | translate }}</span>
                            <mat-error
                                *ngIf="
                                    heatingBoilerFormGroup
                                        .get('seasonalSpaceHeatingEnergyEfficiencyControl')
                                        ?.hasError('required')
                                "
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'create_product.nominalHeatOutput' | translate }}</mat-label>
                            <input type="number" matInput formControlName="nominalHeatOutputControl" required />
                            <span matTextSuffix>{{ 'create_product.w' | translate }}</span>
                            <mat-error
                                *ngIf="heatingBoilerFormGroup.get('nominalHeatOutputControl')?.hasError('required')"
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                </tr>

                <tr>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{
                                'create_product.temperatureControllerClassification' | translate
                            }}</mat-label>
                            <mat-select
                                formControlName="temperatureControllerClassificationControl"
                                name="temperatureControllerClassification"
                                required
                            >
                                <mat-option
                                    *ngFor="
                                        let classificationOfTemperatureController of classificationsOfTemperatureControllers
                                    "
                                    [value]="classificationOfTemperatureController.value"
                                >
                                    {{ classificationOfTemperatureController.viewValue }}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="
                                    heatingBoilerFormGroup
                                        .get('temperatureControllerClassificationControl')
                                        ?.hasError('required')
                                "
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                </tr>
            </table>
        </div>

        <div
            *ngIf="
                mainFormGroup.get('productCategoryControl')?.value.value ===
                    'heatingWaterBufferCylinderWithDHWHeating' ||
                mainFormGroup.get('productCategoryControl')?.value.value === 'heatingWaterBufferCylinder' ||
                mainFormGroup.get('productCategoryControl')?.value.value === 'dhwCylinderHeating'
            "
            [formGroup]="heatingCylinderFormGroup"
        >
            <table class="full-width">
                <tr>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'create_product.cylinderVolume' | translate }}</mat-label>
                            <input type="number" matInput formControlName="cylinderVolumeControl" required />
                            <span matTextSuffix>{{ 'create_product.litre' | translate }}</span>
                            <mat-error
                                *ngIf="heatingCylinderFormGroup.get('cylinderVolumeControl')?.hasError('required')"
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'create_product.heatLosses' | translate }}</mat-label>
                            <input type="number" matInput formControlName="heatLossesControl" required />
                            <span matTextSuffix>{{ 'create_product.w' | translate }}</span>
                            <mat-error *ngIf="heatingCylinderFormGroup.get('heatLossesControl')?.hasError('required')">
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td
                        *ngIf="
                            mainFormGroup.get('productCategoryControl')?.value.value !== 'heatingWaterBufferCylinder'
                        "
                    >
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'create_product.nonSolarCylinderVolume' | translate }}</mat-label>
                            <input type="number" matInput formControlName="nonSolarCylinderVolumeControl" required />
                            <span matTextSuffix>{{ 'create_product.litre' | translate }}</span>
                            <mat-error
                                *ngIf="
                                    heatingCylinderFormGroup.get('nonSolarCylinderVolumeControl')?.hasError('required')
                                "
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                </tr>
            </table>
        </div>

        <div
            *ngIf="mainFormGroup.get('productCategoryControl')?.value.value === 'solarCircuitDistribution'"
            [formGroup]="solarCircuitFormGroup"
        >
            <table class="full-width">
                <tr>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'create_product.pumpPowerConsumption' | translate }}</mat-label>
                            <input type="number" matInput formControlName="pumpPowerConsumptionControl" required />
                            <span matTextSuffix>{{ 'create_product.w' | translate }}</span>
                            <mat-error
                                *ngIf="solarCircuitFormGroup.get('pumpPowerConsumptionControl')?.hasError('required')"
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'create_product.pumpStandByPower' | translate }}</mat-label>
                            <input type="number" matInput formControlName="pumpStandByPowerControl" required />
                            <span matTextSuffix>{{ 'create_product.w' | translate }}</span>
                            <mat-error
                                *ngIf="solarCircuitFormGroup.get('pumpStandByPowerControl')?.hasError('required')"
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                </tr>
            </table>
        </div>

        <div
            *ngIf="mainFormGroup.get('productCategoryControl')?.value.value === 'solarCollector'"
            [formGroup]="solarCollectorFormGroup"
        >
            <table class="full-width">
                <tr>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'create_product.collectorSize' | translate }}</mat-label>
                            <input type="number" matInput formControlName="collectorSizeControl" required />
                            <span matTextSuffix>{{ 'create_product.m2' | translate }}</span>
                            <mat-error
                                *ngIf="solarCollectorFormGroup.get('collectorSizeControl')?.hasError('required')"
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'create_product.collectorEfficiency' | translate }}</mat-label>
                            <input type="number" matInput formControlName="collectorEfficiencyControl" required />
                            <span matTextSuffix>{{ 'create_product.percent' | translate }}</span>
                            <mat-error
                                *ngIf="solarCollectorFormGroup.get('collectorEfficiencyControl')?.hasError('required')"
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'create_product.collectorOpticalEfficiency' | translate }}</mat-label>
                            <input
                                type="number"
                                matInput
                                formControlName="collectorOpticalEfficiencyControl"
                                required
                            />
                            <span matTextSuffix>{{ 'create_product.percent' | translate }}</span>
                            <mat-error
                                *ngIf="
                                    solarCollectorFormGroup
                                        .get('collectorOpticalEfficiencyControl')
                                        ?.hasError('required')
                                "
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'create_product.angleCorrectionFactor' | translate }}</mat-label>
                            <input type="number" matInput formControlName="angleCorrectionFactorControl" required />
                            <mat-error
                                *ngIf="
                                    solarCollectorFormGroup.get('angleCorrectionFactorControl')?.hasError('required')
                                "
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'create_product.linearHeatTransferCoefficient' | translate }}</mat-label>
                            <input
                                type="number"
                                matInput
                                formControlName="linearHeatTransferCoefficientControl"
                                required
                            />
                            <mat-error
                                *ngIf="
                                    solarCollectorFormGroup
                                        .get('linearHeatTransferCoefficientControl')
                                        ?.hasError('required')
                                "
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'create_product.squareHeatTransferCoefficient' | translate }}</mat-label>
                            <input
                                type="number"
                                matInput
                                formControlName="squareHeatTransferCoefficientControl"
                                required
                            />
                            <mat-error
                                *ngIf="
                                    solarCollectorFormGroup
                                        .get('squareHeatTransferCoefficientControl')
                                        ?.hasError('required')
                                "
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'create_product.pumpPowerConsumption' | translate }}</mat-label>
                            <input type="number" matInput formControlName="pumpPowerConsumptionControl" required />
                            <span matTextSuffix>{{ 'create_product.w' | translate }}</span>
                            <mat-error
                                *ngIf="solarCollectorFormGroup.get('pumpPowerConsumptionControl')?.hasError('required')"
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'create_product.pumpStandByPower' | translate }}</mat-label>
                            <input type="number" matInput formControlName="pumpStandByPowerControl" required />
                            <span matTextSuffix>{{ 'create_product.w' | translate }}</span>
                            <mat-error
                                *ngIf="solarCollectorFormGroup.get('pumpStandByPowerControl')?.hasError('required')"
                            >
                                {{ 'errors.field_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                </tr>
            </table>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button cdkFocusInitial (click)="createProduct()">
        {{ (editingId ? 'create_product.edit' : 'create_product.add') | translate }}
    </button>
    <button mat-button mat-dialog-close cdkFocusInitial>{{ 'create_product.cancel' | translate }}</button>
</mat-dialog-actions>
