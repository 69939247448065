import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    private menu: MatSidenav;

    setMenu(menu: MatSidenav) {
        this.menu = menu;
    }

    toggle() {
        this.menu.toggle();
    }

    close() {
        this.menu.close();
    }
}
