<h2 *ngIf="data.mode === 'select'" mat-dialog-title>{{ 'select_product.title' | translate }}</h2>
<h2 *ngIf="data.mode === 'admin'" mat-dialog-title>{{ 'admin_product.title' | translate }}</h2>
<mat-dialog-content>
    <table class="table" *ngIf="!isLoading">
        <tbody>
            <tr *ngIf="products.length === 0">
                <td>{{ 'select_product.no_products_to_display' | translate }}</td>
            </tr>
            <tr *ngFor="let item of products">
                <td>{{ item.specifics.PRODUKTNAME }}</td>
                <td>{{ getItemType(item.typeId) }}</td>
                <td>
                    <button *ngIf="data.mode === 'select'" mat-button (click)="selectProduct(item)">
                        {{ 'select_product.accept' | translate }}
                    </button>
                    <button
                        *ngIf="data.mode === 'admin'"
                        mat-button
                        (click)="deleteProduct(item)"
                        [disabled]="isDeleting.includes(item._id || '')"
                    >
                        {{ 'select_product.delete' | translate }}
                    </button>
                    <button
                        *ngIf="data.mode === 'admin'"
                        mat-button
                        [mat-dialog-close]="item"
                        [disabled]="isDeleting.includes(item._id || '')"
                    >
                        {{ 'select_product.edit' | translate }}
                    </button>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="loader-progress" *ngIf="isLoading">
        <mat-progress-spinner class="spinner" mode="indeterminate"></mat-progress-spinner>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>{{ 'select_product.cancel' | translate }}</button>
</mat-dialog-actions>
