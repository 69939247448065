import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { filter } from 'rxjs/operators';
import { PlannedMaintenance, StatusPageService, StatusSummary } from '../services/status-page.service';

@Component({
    selector: 'app-maintenance-message',
    templateUrl: './maintenance-message.component.html',
    styleUrls: ['./maintenance-message.component.scss'],
})
export class MaintenanceMessageComponent implements OnInit {
    public visible = false;
    public maintenance?: PlannedMaintenance;
    public partialOutage: boolean;

    public dateFormat = 'dd.MM.yyyy HH:mm';

    constructor(private statusPage: StatusPageService) {}

    ngOnInit(): void {
        this.statusPage
            .getStatus()
            .pipe(filter((s) => !!s))
            .subscribe((status: StatusSummary | undefined) => {
                if (status) {
                    this.partialOutage = ['partial_outage', 'degraded_performance'].includes(status.status);
                    this.maintenance = this.getMaintenance(status);
                    this.visible = this.partialOutage || !!this.maintenance;
                }
            });
    }

    private getMaintenance(status: StatusSummary): PlannedMaintenance | undefined {
        const maintenance = status.next_maintenance;
        if (maintenance) {
            // start showing maintenance message 7 days before start date
            const timeToShow = moment(maintenance.start_date).subtract(7, 'days');
            const now = moment();
            if (timeToShow.isBefore(now)) {
                return maintenance;
            }
        }
        return;
    }
}
