<div fxLayout="column" *ngIf="relevant.length > 0 || notRelevant.length > 0">
    <div class="h3">{{ 'content.salesReceiptResult.searchResults' | translate }}</div>
    <mat-checkbox class="show-not-applicable" (change)="relevantToggle()">{{
        'content.salesReceiptResult.showNotRelevant' | translate
    }}</mat-checkbox>
    <div class="center-text" *ngIf="relevant.length === 0">
        {{ 'content.salesReceiptResult.noRelevantResults' | translate }}
    </div>
    <table mat-table [dataSource]="dataSource" class="search-results">
        <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef class="p-16 regular-text">
                <mat-checkbox
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox
                    *ngIf="relevant.includes(element)"
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(element) : null"
                    [checked]="selection.isSelected(element)"
                >
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="p-16 regular-text">
                {{ 'content.salesReceiptResult.idColumn' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" [class.not-relevant]="!relevant.includes(element)">
                {{ element.id }}
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef class="p-16 regular-text">
                {{ 'content.salesReceiptResult.descriptionColumn' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" [class.not-relevant]="!relevant.includes(element)">
                {{ element.shortDescription }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
<div fxLayoutAlign="center center" *ngIf="dataSource.length > 0">
    <button
        class="add-to-project"
        mat-raised-button
        color="primary"
        [disabled]="selection.selected.length === 0 || addingCallInProgress"
        [class.btnspinner]="addingCallInProgress"
        (click)="addToProject()"
    >
        <mat-icon>system_update_alt</mat-icon>
        {{ 'content.salesReceiptResult.addToProject' | translate }}
    </button>
</div>
