<form fxLayout="row">
    <mat-form-field appearance="outline">
        <mat-label>{{ 'content.salesReceipt.inputLabel' | translate }}</mat-label>
        <input matInput [formControl]="salesSearchCtrl" />
    </mat-form-field>
    <div class="search-button" fxLayout="column">
        <button class="search-receipt" mat-flat-button (click)="search()">
            <span>{{ 'content.salesReceipt.buttonText' | translate }}</span>
        </button>
    </div>
</form>
