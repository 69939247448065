import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { LabelUrls } from '../../../services/backend.service';

@Component({
    selector: 'app-label-dialog',
    templateUrl: './label-dialog.component.html',
    styleUrls: ['./label-dialog.component.scss'],
})
export class LabelDialogComponent {
    imageData: SafeUrl | null = null;
    attachGeneralInfo = true;
    attachProductLabelsAndDatasheets = true;
    baseUrl = '';
    pdfUrl = '';
    generationTime = {
        min: 0,
        max: 0,
    };
    timeWarning = '';

    constructor(
        private sanitizer: DomSanitizer,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            image: Blob;
            labelUrls: LabelUrls;
        },
    ) {
        this.baseUrl = `${environment.apiUrl}/energy-label/v1/`;
        this.imageData = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.image));
        this.recalculate();
    }

    recalculate() {
        if (this.attachGeneralInfo && this.attachProductLabelsAndDatasheets) {
            this.pdfUrl = this.baseUrl + this.data.labelUrls.fullPdfUrlInfo;
            this.generationTime.min = 19;
            this.generationTime.max = 28;
        } else if (this.attachGeneralInfo) {
            this.pdfUrl = this.baseUrl + this.data.labelUrls.pdfUrlInfo;
            this.generationTime.min = 16;
            this.generationTime.max = 22;
        } else if (this.attachProductLabelsAndDatasheets) {
            this.pdfUrl = this.baseUrl + this.data.labelUrls.fullPdfUrl;
            this.generationTime.min = 13;
            this.generationTime.max = 19;
        } else {
            this.pdfUrl = this.baseUrl + this.data.labelUrls.pdfUrl;
            this.generationTime.min = 9;
            this.generationTime.max = 13;
        }
        this.timeWarning = this.translate.instant('content.labelDialog.generateTimeWarning', this.generationTime);
    }
}
