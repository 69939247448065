<ng-container *ngIf="heatGenerator">
    <tr>
        <th>{{ heatGenerator.generator.id }}</th>
        <td class="semi-bold-text" colspan="2">{{ heatGenerator.generator.longDescription }}</td>
        <td class="optionButtons">
            <button
                *ngIf="generatorId > 1 && !lockOrder"
                mat-icon-button
                class="table-button"
                matTooltip="{{ 'table.moveHeaterUp' | translate: { target: generatorId } }}"
                (click)="moveGeneratorUp.emit(generatorId)"
            >
                <mat-icon>arrow_upward</mat-icon>
            </button>
        </td>
        <td class="deleteButton">
            <button mat-icon-button class="table-button" (click)="removeGenerator.emit(generatorId)">
                <mat-icon>close</mat-icon>
            </button>
        </td>
        <td class="heating"></td>
        <td class="hotWater"></td>
    </tr>
    <tr *ngFor="let element of heatGenerator.elements">
        <td class="elementName">
            {{ getElementName(element) | translate }}
        </td>
        <td>{{ getElementId(element) }}</td>
        <td>{{ getElementDescription(element) }}</td>
        <td class="optionButtons">
            <app-download-documents-menu
                *ngIf="!element.quantity && !element.roomHookup"
                [element]="element.productElement"
            ></app-download-documents-menu>
            <button class="table-button" *ngIf="element.quantity" (click)="editQuantity.emit(element.productElement)">
                <mat-icon>edit</mat-icon>
            </button>
            <button
                class="table-button"
                *ngIf="element.roomHookup"
                (click)="editRoomHookupForControlUnitAccessory.emit(element.productElement)"
            >
                <mat-icon>edit</mat-icon>
            </button>
        </td>
        <td></td>
        <td class="heating">{{ getHeatingEfficiency(element) }}</td>
        <td class="hotWater">{{ getHotWaterEfficiency(element) }}</td>
    </tr>
</ng-container>
<tr *ngIf="!heatGenerator?.generator" [class.error]="requiredError">
    <th>{{ 'table.heat_generator_' + generatorId | translate }}</th>
    <td *ngIf="requiredError">{{ 'table.required' | translate }}</td>
    <td *ngIf="!requiredError">{{ 'table.optional' | translate }}</td>
    <td></td>
    <td class="optionButtons"></td>
    <td></td>
    <td class="heating"></td>
    <td class="hotWater"></td>
</tr>
