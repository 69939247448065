export interface ProductEnumValue {
    value: string;
    viewValue: string;
}

export interface ProductCategoryValue extends ProductEnumValue {
    typeId: string;
}

export const PRODUCT_CATEGORIES: ProductCategoryValue[] = [
    {
        viewValue: 'create_product.categories.heatingBoiler',
        value: 'heatingBoiler',
        typeId: '1',
    },
    {
        viewValue: 'create_product.categories.heatPump',
        value: 'heatPump',
        typeId: '3',
    },
    {
        viewValue: 'create_product.categories.solarCollector',
        value: 'solarCollector',
        typeId: '6',
    },
    {
        viewValue: 'create_product.categories.heatingWaterBufferCylinder',
        value: 'heatingWaterBufferCylinder',
        typeId: '5',
    },
    {
        viewValue: 'create_product.categories.dhwCylinderHeating',
        value: 'dhwCylinderHeating',
        typeId: '8',
    },
    {
        viewValue: 'create_product.categories.controlUnitAccessories',
        value: 'controlUnitAccessories',
        typeId: '9',
    },
    {
        viewValue: 'create_product.categories.heatingWaterBufferCylinderWithDHWHeating',
        value: 'heatingWaterBufferCylinderWithDHWHeating',
        typeId: '13',
    },
    {
        viewValue: 'create_product.categories.solarCircuitDistribution',
        value: 'solarCircuitDistribution',
        typeId: '18',
    },
];
