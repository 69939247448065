import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductElement } from '../../../../services/backend.service';

@Component({
    selector: 'app-change-quantity',
    templateUrl: './change-quantity.component.html',
    styleUrls: ['./change-quantity.component.scss'],
})
export class ChangeQuantityComponent {
    dialogValue = 0;

    constructor(@Inject(MAT_DIALOG_DATA) public data: ProductElement) {
        this.dialogValue = data.config.quantity || 0;
    }
}
