import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Efficiency, LabelUrls, Product } from './backend.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class EverestBackendService {
    constructor(private http: HttpClient) {}

    getProject(projectId: string) {
        return this.http.get<Project>(`${environment.everestApiUrl}/erp/v1/projects/${projectId}`);
    }

    saveProject(projectId: string, project: Project) {
        return this.http.put<Project>(`${environment.everestApiUrl}/erp/v1/projects/${projectId}`, project);
    }

    createProduct(product: CustomProduct) {
        return this.http.post<CustomProduct>(`${environment.everestApiUrl}/erp/v1/products`, product);
    }

    getProducts() {
        return this.http.get<CustomProduct[]>(`${environment.everestApiUrl}/erp/v1/products`);
    }

    updateProduct(product: CustomProduct) {
        return this.http.put<CustomProduct>(`${environment.everestApiUrl}/erp/v1/products/${product._id}`, product);
    }

    deleteProduct(product: CustomProduct) {
        return this.http.delete<CustomProduct>(`${environment.everestApiUrl}/erp/v1/products/${product._id}`);
    }

    getProduct(product: CustomProduct) {
        return this.http.get<Product>(`${environment.everestApiUrl}/erp/v1/products/${product._id}`);
    }

    getEmployee() {
        return this.http.get<SalesFunctions>(`${environment.everestApiUrl}/erp/v1/employee`);
    }

    getCustomer(companyId: string) {
        return this.http.get<CustomerData>(`${environment.everestApiUrl}/erp/v1/customer/data?customer=${companyId}`);
    }
}

export interface Project {
    name: string;
    mode: {
        type: string;
        postData?: string;
    };
    label: LabelUrls | {};
    efficiency: Efficiency | {};
    items: Product[];
    savedData: Product[];
    config: object;
    error: object | null;
    countryId: string;
    langId: string;
}

export interface CustomProduct {
    _id?: string;
    typeId: string;
    specifics:
        | ControlUnitAccessoriesSpecifics
        | HeatingCylinderSpecifics
        | SolarCircuitDistributionSpecifics
        | SolarCollectorSpecifics
        | HeatPumpSpecifics
        | HeatingBoilerSpecifics;
}

export interface ControlUnitAccessoriesSpecifics {
    PRODUKTNAME: string;
    E_REG_KLASSE_ROM: string;
    E_REG_KLASSE?: number;
}

export interface HeatingCylinderSpecifics {
    PRODUKTNAME: string;
    SPEICHERINHALT: number;
    E_WARMHALTEVERLUSTE: number;
    NICHTSOLARES_SPEICHERVOL?: number;
}

export interface HeatPumpSpecifics {
    PRODUKTNAME: string;
    E_REG_KLASSE: number; // 1-5, including 1.5 and 3.5
    E_REG_KLASSE_ROM: string; // I - VIII
    NENNLEISTUNG_WP_MT: string; // kW, open text number
    WIRKUNGSGRAD_ETA_S_MT: string; // %, open text number
}
export interface HeatingBoilerSpecifics {
    PRODUKTNAME: string;
    E_REG_KLASSE_ROM: string;
    P_RATED: number;
    WIRKUNGSGRAD_ETA_S: string;
}

export interface SolarCircuitDistributionSpecifics {
    PRODUKTNAME: string;
    LEI_AUF1: number;
    LEIST_AUFNAHME_PUMPE_BR: number;
}

export interface SolarCollectorSpecifics {
    PRODUKTNAME: string;
    APERTURFLAECHE: number;
    E_ETA0: number;
    E_K1: number;
    E_K2: number;
    E_WIRKUNGSGRAD_KOLL: number;
    LEIST_AUFNAHME_PUMPE_BR?: number;
    LEI_AUF1?: number;
    WINKELKORREKTUR: number;
}

export interface SalesFunctions {
    salesFunctions: SalesFunction[];
}

export interface SalesFunction {
    salesOrg: string;
}

export interface CustomerData {
    primarySalesOrg: string;
}
