import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from './body/content/content.component';
import { MaintenancePageComponent } from './maintenance/maintenance-page/maintenance-page.component';
import { MaintenanceGuard } from './maintenance/services/maintenance.guard';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
    {
        path: 'external/:projectId',
        pathMatch: 'full',
        canActivate: [AuthGuardService, MaintenanceGuard],
        component: ContentComponent,
    },
    {
        path: '',
        pathMatch: 'full',
        canActivate: [AuthGuardService, MaintenanceGuard],
        component: ContentComponent,
    },
    {
        path: 'maintenance',
        component: MaintenancePageComponent,
        pathMatch: 'full',
    },
    { path: '**', redirectTo: '', canActivate: [MaintenanceGuard] },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
