<mat-toolbar color="primary">
    <button mat-icon-button (click)="menuClick()">
        <mat-icon>menu</mat-icon>
    </button>
    <img src="../../assets/viessmann-white.svg" class="logo-image" />
    <div class="rectangle"></div>
    <span class="name">{{ 'header.appName' | translate }}</span>

    <div fxFlex="1 0 auto"></div>

    <vih-app-switcher></vih-app-switcher>
    <vih-login [appManagesAuthentication]="true" [authenticated]="authenticated$"></vih-login>
</mat-toolbar>
