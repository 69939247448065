<h2 mat-dialog-title>{{ data.shortDescription }} ({{ data.id }})</h2>
<mat-dialog-content>
    <label>{{ 'change_usage.what_is_solar_used_for' | translate }}</label>
    <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="change-usage-radio-group"
        [(ngModel)]="dialogValue"
    >
        <mat-radio-button class="change-usage-radio-button" *ngFor="let usage of usages" [value]="usage.id">
            {{ usage.translationKey | translate }}
        </mat-radio-button>
    </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="dialogValue" cdkFocusInitial [disabled]="!dialogValue">
        {{ 'change_usage.ok' | translate }}
    </button>
    <button mat-button mat-dialog-close>{{ 'change_usage.cancel' | translate }}</button>
</mat-dialog-actions>
