import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '../services/menu.service';

@Component({
    selector: 'app-body',
    templateUrl: './body.component.html',
    styleUrls: ['./body.component.scss'],
})
export class BodyComponent implements AfterViewInit {
    @ViewChild('sidenav') sidenav: MatSidenav;

    constructor(
        private menuService: MenuService,
        private translate: TranslateService,
    ) {}

    ngAfterViewInit() {
        this.menuService.setMenu(this.sidenav);
    }
}
