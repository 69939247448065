import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StatusPageService } from './status-page.service';

@Injectable()
export class MaintenanceGuard {
    constructor(private statusPage: StatusPageService) {}

    public canActivate(): Observable<boolean> {
        return this.statusPage.checkForDowntime().pipe(map((downTime) => !downTime));
    }
}
