<button mat-flat-button style="height: 0; position: absolute"></button>
<button mat-flat-button class="close-menu" (click)="closeMenu()">
    <span class="semi-bold-text">{{ 'menu.closeMenu' | translate }}</span>
    <mat-icon>close</mat-icon>
</button>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="menu-tree">
    <mat-tree-node *matTreeNodeDef="let node" class="row nested" matRipple (click)="node.click()">
        <span class="regular-text">{{ node.name | translate }}</span>
    </mat-tree-node>

    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div matTreeNodeToggle class="row">
            <div matRipple fxLayout="row" fxLayoutAlign="start center">
                <mat-icon>
                    {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
                <span class="semi-bold-text">{{ node.name | translate }}</span>
            </div>
        </div>
        <div [class.d-none]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
        </div>
    </mat-nested-tree-node>
</mat-tree>
