import { Component } from '@angular/core';
import { StatusSummary } from '../services/status-page.service';

@Component({
    selector: 'app-maintenance-page',
    templateUrl: './maintenance-page.component.html',
    styleUrls: ['./maintenance-page.component.scss'],
})
export class MaintenancePageComponent {
    public type: string;
    constructor() {
        const state: StatusSummary = history.state;
        this.type = state?.status?.toUpperCase() || 'UNDER_MAINTENANCE';
    }
}
