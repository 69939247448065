import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
    BackendService,
    BURNER_IDS,
    CONTROL_UNIT_ACCESSORIES_IDS,
    CONTROL_UNIT_IDS,
    DHW_CYLINDER_IDS,
    DOMESTIC_VENTILATION_IDS,
    Efficiency,
    ELECTRIC_WATER_HEATER_IDS,
    HEAT_GENERATOR_IDS,
    HEAT_PUMPS,
    HeatGenerator,
    HeatGeneratorElement,
    HEATING_WATER_BUFFER_CYLINDER_IDS,
    HEATING_WATER_BUFFER_CYLINDER_WITH_DHW_HEATING_IDS,
    Product,
    ProductElement,
    SOLAR_CIRCUIT_DISTRIBUTION_IDS,
    SOLAR_COLLECTOR_IDS,
    SOLAR_COLLECTOR_USAGES,
} from '../../../services/backend.service';
import { ProductService } from '../../../services/product.service';
import { UserDataService } from '../../../services/user-data.service';
import { ChangeQuantityComponent } from './change-quantity/change-quantity.component';
import { ChangeRoomHookupComponent } from './change-room-hookup/change-room-hookup.component';
import { ChangeUsageComponent } from './change-usage/change-usage.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';

const CONTROL_UNIT_ALREADY_ADDED = 'errors.control_unit_already_added';

@Component({
    selector: 'app-product-table',
    templateUrl: './product-table.component.html',
    styleUrls: ['./product-table.component.scss'],
})
export class ProductTableComponent implements OnInit {
    @Output()
    generatedLabel = new EventEmitter();

    heatGenerators: HeatGenerator[] = [];
    controlUnit?: Product;
    controlUnitAccessories?: Product;
    heatingWaterBufferCylinders: Product[] = [];
    heatingWaterBufferCylindersWithDHWHeating: Product[] = [];
    dhwCylinders: Product[] = [];
    solarCircuitDistributions: Product[] = [];
    domesticVentilations: Product[] = [];
    solarCollectors: Product[] = [];
    electricWaterHeaters: Product[] = [];
    language = 'de';
    vkorg = '0500';
    missingElements: string[] = [];
    wrongOrderError = false;
    lockHeatersOrder = false;
    efficiency?: Efficiency;
    error: object | null = null;
    controlUnitAccessoryFromPackage = false;
    heatingWaterBufferCylindersWithDHWHeatingFromPackage = false;
    containsSecondaryHeatGenerator = false;

    constructor(
        private backend: BackendService,
        private userData: UserDataService,
        private snackBar: MatSnackBar,
        private translationService: TranslateService,
        private dialog: MatDialog,
        private productService: ProductService,
    ) {
        this.userData.language$.subscribe((val) => (this.language = val));
        this.userData.catalog$.subscribe((val) => {
            if (this.vkorg !== val) {
                this.clearData();
            }
            this.vkorg = val;
        });
    }

    clearData() {
        this.heatGenerators = [];
        this.controlUnit = undefined;
        this.controlUnitAccessories = undefined;
        this.controlUnitAccessoryFromPackage = false;
        this.heatingWaterBufferCylindersWithDHWHeatingFromPackage = false;
        this.heatingWaterBufferCylinders = [];
        this.dhwCylinders = [];
        this.solarCircuitDistributions = [];
        this.domesticVentilations = [];
        this.solarCollectors = [];
        this.electricWaterHeaters = [];
        this.updateLabelsAndEfficiency();
        this.containsSecondaryHeatGenerator = false;
    }

    ngOnInit() {
        this.updateLabelsAndEfficiency();
        this.productService.addCustomProduct.subscribe((customProduct) => {
            this.selectProduct(customProduct);
        });
    }
    selectProduct(product: Product, showErrors = true) {
        const productTypeId = product?.elements[0]?.type?.id;

        if (!productTypeId) {
            if (showErrors) {
                this.snackBar.open(
                    this.translationService.instant('errors.invalid_product_type'),
                    this.translationService.instant('errors.ok'),
                );
                return;
            } else {
                return this.translationService.instant('errors.invalid_product_type');
            }
        }

        if (HEAT_GENERATOR_IDS.includes(productTypeId)) {
            return this.addHeatGenerator(product, showErrors);
        } else if (CONTROL_UNIT_IDS.includes(productTypeId)) {
            return this.addControlUnit(product, showErrors);
        } else if (CONTROL_UNIT_ACCESSORIES_IDS.includes(productTypeId)) {
            return this.addControlUnitAccessories(product, showErrors);
        } else if (SOLAR_COLLECTOR_IDS.includes(productTypeId)) {
            this.addSolarCollector(product);
        } else if (HEATING_WATER_BUFFER_CYLINDER_IDS.includes(productTypeId)) {
            this.heatingWaterBufferCylinders.push(product);
            this.updateLabelsAndEfficiency();
        } else if (HEATING_WATER_BUFFER_CYLINDER_WITH_DHW_HEATING_IDS.includes(productTypeId)) {
            this.heatingWaterBufferCylindersWithDHWHeating.push(product);
            this.updateLabelsAndEfficiency();
        } else if (DHW_CYLINDER_IDS.includes(productTypeId)) {
            this.dhwCylinders.push(product);
            this.updateLabelsAndEfficiency();
        } else if (SOLAR_CIRCUIT_DISTRIBUTION_IDS.includes(productTypeId)) {
            this.solarCircuitDistributions.push(product);
            this.updateLabelsAndEfficiency();
        } else if (DOMESTIC_VENTILATION_IDS.includes(productTypeId)) {
            this.domesticVentilations.push(product);
            this.updateLabelsAndEfficiency();
        } else if (ELECTRIC_WATER_HEATER_IDS.includes(productTypeId)) {
            this.electricWaterHeaters.push(product);
            this.updateLabelsAndEfficiency();
        } else {
            if (showErrors) {
                this.snackBar.open(
                    this.translationService.instant('errors.unsupported_product', { id: product.id }),
                    this.translationService.instant('errors.ok'),
                );
            } else {
                return this.translationService.instant('errors.unsupported_product', { id: product.id });
            }
        }
    }

    getControlUnitHeatingEfficiency() {
        return this.efficiencies.heatGenerator1.controlUnit?.heating
            ? `${this.getSign(this.efficiencies.heatGenerator1.controlUnit?.heating)}${Number.parseFloat(
                  this.efficiencies.heatGenerator1.controlUnit?.heating,
              )} %`
            : '';
    }

    getControlUnitHotWaterEfficiency() {
        return this.efficiencies.heatGenerator1.controlUnit?.water
            ? `${Number.parseFloat(this.efficiencies.heatGenerator1.controlUnit?.water)} %`
            : '';
    }

    getSign(efficiencyNumberRepresentation: string, hidePlus = false) {
        return efficiencyNumberRepresentation.includes('-') ? '- ' : hidePlus ? '' : '+ ';
    }

    private updateLabelsAndEfficiency() {
        const products = this.collectProducts();

        this.updateLabels(products);
        this.updateEfficiency(products);
    }

    public collectProducts() {
        const products = [
            ...this.heatGenerators.map((heatGenerator) => heatGenerator.generator),
            ...this.heatingWaterBufferCylinders,
            ...this.heatingWaterBufferCylindersWithDHWHeating,
            ...this.dhwCylinders,
            ...this.solarCircuitDistributions,
            ...this.domesticVentilations,
            ...this.solarCollectors,
            ...this.electricWaterHeaters,
        ];
        if (this.controlUnit) {
            products.push(this.controlUnit);
        }
        if (this.controlUnitAccessories) {
            products.push(this.controlUnitAccessories);
        }

        return products;
    }

    efficiencies: {
        heatGenerator1: ProductEfficiency;
        heatGenerator2: ProductEfficiency;
        total?: Efficiency;
    } = {
        heatGenerator1: {},
        heatGenerator2: {},
    };

    private updateLabels(products: Product[]) {
        this.backend
            .labels(products, this.vkorg, this.language)
            .pipe(
                catchError((err) => {
                    if (err.status === 400) {
                        this.missingElements = [];
                        this.error = err.error;

                        if (err.error?.validation?.we_order === 'INVALID') {
                            this.wrongOrderError = true;
                        }
                        const missingTypes: string[] = err.error?.validation?.missing_types || [];
                        if (missingTypes.find((t) => HEAT_GENERATOR_IDS.includes(t))) {
                            this.missingElements.push('boiler');
                        }
                        if (missingTypes.find((t) => CONTROL_UNIT_IDS.includes(t))) {
                            this.missingElements.push('controlUnit');
                        }
                        if (missingTypes.find((t) => SOLAR_COLLECTOR_IDS.includes(t))) {
                            this.missingElements.push('solarCollector');
                        }
                        if (missingTypes.find((t) => HEATING_WATER_BUFFER_CYLINDER_IDS.includes(t))) {
                            this.missingElements.push('heatWaterBufferCylinder');
                        }
                        if (missingTypes.find((t) => HEATING_WATER_BUFFER_CYLINDER_WITH_DHW_HEATING_IDS.includes(t))) {
                            this.missingElements.push('heatWaterBufferCylinderWithDHW');
                        }
                        if (missingTypes.find((t) => DHW_CYLINDER_IDS.includes(t))) {
                            this.missingElements.push('dhwCylinder');
                        }
                        if (missingTypes.find((t) => SOLAR_CIRCUIT_DISTRIBUTION_IDS.includes(t))) {
                            this.missingElements.push('solarCircuitDistribution');
                        }
                        if (missingTypes.find((t) => DOMESTIC_VENTILATION_IDS.includes(t))) {
                            this.missingElements.push('domesticVentilation');
                        }
                        if (missingTypes.find((t) => ELECTRIC_WATER_HEATER_IDS.includes(t))) {
                            this.missingElements.push('electricWaterHeater');
                        }
                        if (missingTypes.find((t) => CONTROL_UNIT_ACCESSORIES_IDS.includes(t))) {
                            this.missingElements.push('controlUnitAccessories');
                        }
                    }
                    return of(null);
                }),
            )
            .subscribe((label) => {
                if (this.wrongOrderError) {
                    this.heatGenerators = this.heatGenerators.reverse();
                    this.updateGeneratorElements(this.heatGenerators[0]?.generator, true);
                    this.updateGeneratorElements(this.heatGenerators[1]?.generator, false);
                    this.wrongOrderError = false;

                    this.dialog.open(MessageDialogComponent, {
                        data: {
                            message: 'table.changed_order_automatically',
                        },
                    });
                    this.lockHeatersOrder = true;
                    this.updateLabelsAndEfficiency();
                    return;
                } else if (label) {
                    this.error = null;
                    this.missingElements = [];
                }
                this.generatedLabel.emit(label);
            });
    }

    private updateGeneratorElements(generator: Product | undefined, isPrimary: boolean) {
        generator?.elements.forEach((element) => {
            if (element.config.primary !== undefined || generator.isCustom) {
                const hasHeatPump = generator?.elements?.some((e) => HEAT_PUMPS.includes(e.type.id));
                element.config.primary = isPrimary && (!hasHeatPump || HEAT_PUMPS.includes(element.type.id));
            }

            if (element.config.combi === null) {
                element.config.combi = false;
            }
        });
        this.correctGeneratorPrimaryFlag(generator);
    }

    private correctGeneratorPrimaryFlag(generator: Product | undefined) {
        const potentialSecondaryGenerator = generator?.elements.find((element) => element.type.id === '16');
        if (
            potentialSecondaryGenerator &&
            generator?.elements.some(
                (element) =>
                    HEAT_GENERATOR_IDS.includes(element.type.id) && element.type.id !== '16' && element.config.primary,
            )
        ) {
            potentialSecondaryGenerator.config.primary = false;
        }
    }

    private updateEfficiency(products: Product[]) {
        this.backend
            .efficiency(products, this.vkorg, this.language)
            .pipe(
                catchError(() => {
                    this.efficiencies = {
                        heatGenerator1: {},
                        heatGenerator2: {},
                    };
                    return of(null);
                }),
            )
            .subscribe((efficiencyResponse) => {
                if (efficiencyResponse) {
                    this.efficiency = efficiencyResponse.efficiency;
                    this.efficiencies = {
                        total: this.efficiency,
                        heatGenerator1: {
                            heating: this.efficiency.h_primary_heater,
                            water: this.efficiency.w_efficiency,
                            controlUnit: {
                                heating: this.efficiency.h_reg_class,
                                water: this.efficiency.w_reg_class,
                            },
                        },
                        heatGenerator2: {
                            heating: this.efficiency.h_second_heater,
                        },
                    };
                }
            });
    }

    public removeProduct(removalEvent: { productArray: Product[]; index: number }) {
        removalEvent.productArray.splice(removalEvent.index, 1);
        this.updateLabelsAndEfficiency();
    }

    private addControlUnit(product: Product, showErrors: boolean) {
        if (!this.controlUnit && !this.firstHeatGeneratorContainsControlUnit()) {
            this.controlUnit = product;
            this.updateLabelsAndEfficiency();
        } else {
            if (showErrors) {
                this.snackBar.open(
                    this.translationService.instant(CONTROL_UNIT_ALREADY_ADDED),
                    this.translationService.instant('errors.ok'),
                );
            } else {
                return this.translationService.instant(CONTROL_UNIT_ALREADY_ADDED);
            }
        }
    }

    private firstHeatGeneratorContainsControlUnit() {
        const firstGenerator = this.heatGenerators[0]?.generator;

        if (!firstGenerator) {
            return false;
        }

        return !!firstGenerator.elements.find((el) => CONTROL_UNIT_IDS.includes(el.type.id));
    }

    private addControlUnitAccessories(product: Product, showErrors: boolean) {
        if (!this.controlUnitAccessories) {
            if (product.elements[0]?.config?.quantity) {
                this.addControlUnitAccessoriesWithQuantity(product);
            } else {
                this.addProductIfQuantitySet(product);
            }
        } else {
            if (showErrors) {
                this.snackBar.open(
                    this.translationService.instant('errors.control_unit_accessories_already_added'),
                    this.translationService.instant('errors.ok'),
                );
            } else {
                return this.translationService.instant('errors.control_unit_accessories_already_added');
            }
        }
    }

    private addControlUnitAccessoriesWithQuantity(product: Product) {
        if (product.elements[0].config.room_hookup === null) {
            this.addControlUnitAccessoriesWithRoomHookupUnknown(product);
        } else {
            this.controlUnitAccessories = product;
            this.updateLabelsAndEfficiency();
        }
    }

    private addControlUnitAccessoriesWithRoomHookupUnknown(product: Product) {
        this.openChangeRoomHookupForControlUnitAccessoryDialog(product.elements[0]).subscribe((result) => {
            if (result !== '') {
                this.controlUnitAccessories = product;
                this.updateLabelsAndEfficiency();
            }
        });
    }

    private openChangeRoomHookupForControlUnitAccessoryDialog(accessory: ProductElement) {
        const selectRoomHookupDialog = this.dialog.open(ChangeRoomHookupComponent, {
            data: accessory,
        });

        const closedSelectRoomHookupDialog = selectRoomHookupDialog.afterClosed();
        closedSelectRoomHookupDialog.subscribe((result) => {
            if (result !== '') {
                accessory.config.room_hookup = result;
            }
        });

        return closedSelectRoomHookupDialog;
    }

    private addSolarCollector(product: Product) {
        if (this.solarCollectors.length > 0) {
            product.elements[0].config.usage = this.solarCollectors[0].elements[0].config.usage;
            this.addSolarCollectorQuantity(product);
        } else {
            this.addSolarCollectorUsage(product);
        }
    }

    private addSolarCollectorUsage(product: Product) {
        if (product.elements[0].config.usage) {
            this.addSolarCollectorQuantity(product);
        } else {
            const selectUsageDialog = this.dialog.open(ChangeUsageComponent, {
                data: product.elements[0],
            });

            selectUsageDialog.afterClosed().subscribe((result) => {
                if (result) {
                    product.elements[0].config.usage = result;
                    this.addSolarCollectorQuantity(product);
                }
            });
        }
    }

    private addSolarCollectorQuantity(product: Product) {
        if (product.elements[0]?.config?.quantity) {
            product.elements = product.elements.map(this.removeEmptyDocumentUrls);

            this.solarCollectors.push(product);
            this.updateLabelsAndEfficiency();
        } else {
            this.addProductIfQuantitySet(product);
        }
    }

    private removeEmptyDocumentUrls = (element: ProductElement) => ({
        ...element,
        documents: element.documents?.filter((d) => d.url?.length > 0) || [],
    });

    private addHeatGenerator(product: Product, showErrors: boolean): string | void {
        // sort heat generators to make heat pumps appear at the top
        product.elements = product.elements.sort((lhs, rhs) => {
            const [a, b] = [lhs, rhs].map((el) => (HEAT_PUMPS.includes(el.type.id) ? 1 : 0));
            return b - a;
        });
        if (
            this.heatGenerators.length === 0 &&
            this.controlUnit &&
            !!product.elements.find((el) => CONTROL_UNIT_IDS.includes(el.type.id))
        ) {
            if (showErrors) {
                this.snackBar.open(
                    this.translationService.instant(CONTROL_UNIT_ALREADY_ADDED),
                    this.translationService.instant('errors.ok'),
                );
                return;
            } else {
                return this.translationService.instant(CONTROL_UNIT_ALREADY_ADDED);
            }
        }

        if (this.heatGenerators.length < 2 && !this.containsSecondaryHeatGenerator) {
            product.elements = product.elements
                .filter((el) =>
                    [
                        ...HEAT_GENERATOR_IDS,
                        ...BURNER_IDS,
                        ...CONTROL_UNIT_IDS,
                        ...DHW_CYLINDER_IDS,
                        ...HEATING_WATER_BUFFER_CYLINDER_IDS,
                        ...CONTROL_UNIT_ACCESSORIES_IDS,
                        ...HEATING_WATER_BUFFER_CYLINDER_WITH_DHW_HEATING_IDS,
                    ].includes(el.type.id),
                )
                .map(this.removeEmptyDocumentUrls);
            if (this.heatGenerators.length === 0) {
                this.updateGeneratorElements(product, true);
            } else {
                this.updateGeneratorElements(product, false);
            }
            this.addHeatGeneratorWithControlUnitAccessoryCheck(product);
        } else {
            if (showErrors) {
                this.snackBar.open(
                    this.translationService.instant('errors.max_heat_generators'),
                    this.translationService.instant('errors.ok'),
                );
            } else {
                return this.translationService.instant('errors.max_heat_generators');
            }
        }

        if (this.heatGenerators.length === 1 && this.checkSecondaryHeatGenerator(this.heatGenerators[0].elements)) {
            this.containsSecondaryHeatGenerator = true;
        }
    }

    private checkSecondaryHeatGenerator(elements: HeatGeneratorElement[]): boolean {
        return elements.some(
            (element) =>
                HEAT_GENERATOR_IDS.includes(element.productElement.type.id) &&
                element.productElement.config.primary === false,
        );
    }

    private addHeatGeneratorWithControlUnitAccessoryCheck(product: Product) {
        const controlUnitAccessoryFromPackage = this.findControlUnitAccessoryFromPackage(product);
        if (controlUnitAccessoryFromPackage && controlUnitAccessoryFromPackage?.config.room_hookup === null) {
            this.openChangeRoomHookupForControlUnitAccessoryDialog(controlUnitAccessoryFromPackage).subscribe(
                (result) => {
                    if (result !== '') {
                        this.addHeatGeneratorToHeatGeneratorsList(product);
                    }
                },
            );
        } else {
            this.addHeatGeneratorToHeatGeneratorsList(product);
        }
    }

    private findControlUnitAccessoryFromPackage(product: Product) {
        return product.elements.find((element) => CONTROL_UNIT_ACCESSORIES_IDS.includes(element.type.id));
    }

    private checkControlUnitAccessoryFromPackage() {
        this.controlUnitAccessoryFromPackage = this.heatGenerators
            .map((heatGenerator) => heatGenerator.generator)
            .some((generator) => !!this.findControlUnitAccessoryFromPackage(generator));
    }

    private checkHeatingWaterBufferCylindersWithDHWHeatingFromPackage() {
        this.heatingWaterBufferCylindersWithDHWHeatingFromPackage = this.heatGenerators
            .map((heatGenerator) => heatGenerator.generator)
            .some((generator) =>
                generator.elements.some((element) =>
                    HEATING_WATER_BUFFER_CYLINDER_WITH_DHW_HEATING_IDS.includes(element.type.id),
                ),
            );
    }

    private addHeatGeneratorToHeatGeneratorsList(heatGenerator: Product) {
        this.heatGenerators.push({
            generator: heatGenerator,
            elements: [],
        });
        this.updateHeatingGeneratorsElementList();
        this.lockHeatersOrder = false;
        this.checkControlUnitAccessoryFromPackage();
        this.checkHeatingWaterBufferCylindersWithDHWHeatingFromPackage();
        this.updateLabelsAndEfficiency();
    }

    private updateHeatingGeneratorsElementList() {
        this.heatGenerators.forEach(
            (heatGenerator) => (heatGenerator.elements = this.prepareHeatGeneratorElements(heatGenerator.generator)),
        );
    }

    private prepareHeatGeneratorElements(generator: Product) {
        const elements: HeatGeneratorElement[] = [];
        generator.elements.forEach((element) => {
            elements.push({ productElement: element });
            if (CONTROL_UNIT_ACCESSORIES_IDS.includes(element.type.id)) {
                if (element.config.quantity) {
                    elements.push({ productElement: element, quantity: element.config.quantity });
                }
                if (element.config.room_hookup != null) {
                    elements.push({ productElement: element, roomHookup: element.config.room_hookup });
                }
            }
        });
        return elements;
    }

    removeGenerator(index: number) {
        this.heatGenerators.splice(index - 1, 1);
        if (this.heatGenerators.length > 0) {
            this.updateGeneratorElements(this.heatGenerators[0].generator, true);
        }
        this.lockHeatersOrder = false;
        this.checkControlUnitAccessoryFromPackage();
        this.checkHeatingWaterBufferCylindersWithDHWHeatingFromPackage();
        this.updateLabelsAndEfficiency();
        this.containsSecondaryHeatGenerator = false;
    }

    moveGeneratorUp(index: number) {
        const generator = this.heatGenerators.splice(index - 1, 1);
        this.heatGenerators.splice(Math.max(index - 2, 0), 0, ...generator);
        this.updateGeneratorElements(this.heatGenerators[0].generator, true);
        for (let i = 1; i < this.heatGenerators.length; i++) {
            this.updateGeneratorElements(this.heatGenerators[i].generator, false);
        }
        this.updateLabelsAndEfficiency();
    }

    removeControlUnitAccessories() {
        this.controlUnitAccessories = undefined;
        this.updateLabelsAndEfficiency();
    }

    removeControlUnit() {
        this.controlUnit = undefined;
        this.updateLabelsAndEfficiency();
    }

    editQuantity(element: ProductElement) {
        const selectProductDialog = this.dialog.open(ChangeQuantityComponent, {
            data: element,
        });

        selectProductDialog.afterClosed().subscribe((result) => {
            if (result) {
                element.config.quantity = result;
                this.updateHeatingGeneratorsElementList();
                this.updateLabelsAndEfficiency();
            }
        });
    }

    editSolarUsage(element: ProductElement) {
        const editSolarUsageDialog = this.dialog.open(ChangeUsageComponent, {
            data: element,
        });

        editSolarUsageDialog.afterClosed().subscribe((result) => {
            if (result) {
                this.solarCollectors.forEach((collector) => {
                    collector.elements[0].config.usage = result;
                });
                this.updateLabelsAndEfficiency();
            }
        });
    }

    addProductIfQuantitySet(product: Product) {
        const selectProductDialog = this.dialog.open(ChangeQuantityComponent, {
            data: product.elements[0],
        });

        selectProductDialog.afterClosed().subscribe((result) => {
            if (result) {
                product.elements[0].config.quantity = result;
                this.selectProduct(product);
            }
        });
    }

    editRoomHookupForControlUnitAccessory(accessory: ProductElement) {
        const selectRoomHookupDialog = this.dialog.open(ChangeRoomHookupComponent, {
            data: accessory,
        });

        selectRoomHookupDialog.afterClosed().subscribe((result) => {
            if (result !== '') {
                accessory.config.room_hookup = result;
                this.updateHeatingGeneratorsElementList();
                this.updateLabelsAndEfficiency();
            }
        });
    }

    getSolarUsageName(usage?: string) {
        return SOLAR_COLLECTOR_USAGES.find((u) => u.id === usage)?.translationKey || '';
    }

    getSolarHeatingEfficiency(element: ProductElement) {
        return this.getSolarEfficiency(element, this.efficiencies?.total?.h_solar);
    }

    getSolarHotWaterEfficiency(element: ProductElement) {
        return this.getSolarEfficiency(element, this.efficiencies?.total?.w_solar);
    }

    private getSolarEfficiency(element: ProductElement, value?: string) {
        return value &&
            SOLAR_COLLECTOR_IDS.includes(element.type.id) &&
            element === this.solarCollectors[0]?.elements[0]
            ? `${Number.parseFloat(value)} %`
            : '';
    }

    getElementName(element: ProductElement) {
        if (SOLAR_COLLECTOR_IDS.includes(element.type.id)) {
            return 'table.solar_collectors';
        } else if (SOLAR_CIRCUIT_DISTRIBUTION_IDS.includes(element.type.id)) {
            return 'table.solar_circuit_distribution';
        } else if (DHW_CYLINDER_IDS.includes(element.type.id)) {
            return 'table.dhw_cylinder_heating';
        }
        return `untranslated_type_id_${element.type.id}`;
    }

    getEfficiencyClass(efficiencyClass?: string) {
        return efficiencyClass ? `class-${efficiencyClass.replace(/\+/g, 'p')}` : '';
    }
}

export interface ProductEfficiency {
    heating?: string;
    water?: string;
    controlUnit?: ProductEfficiency;
}
