import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaintenanceMessageComponent } from './maintenance-message/maintenance-message.component';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { MaintenanceGuard } from './services/maintenance.guard';
import { StatusPageService } from './services/status-page.service';

const routes: Routes = [
    {
        path: '',
        component: MaintenancePageComponent,
    },
];

@NgModule({
    declarations: [MaintenanceMessageComponent, MaintenancePageComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        TranslateModule.forChild(),
        MatButtonModule,
        MatIconModule,
        FlexModule,
    ],
    providers: [MaintenanceGuard, StatusPageService],
    exports: [MaintenanceMessageComponent, MaintenancePageComponent],
})
export class MaintenanceModule {}
