import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BackendService, SalesReceipt } from '../../../services/backend.service';
import { UserDataService } from '../../../services/user-data.service';

@Component({
    selector: 'app-sales-receipt',
    templateUrl: './sales-receipt.component.html',
    styleUrls: ['./sales-receipt.component.scss'],
})
export class SalesReceiptComponent {
    @Output()
    results = new EventEmitter<SalesReceipt[]>();

    salesSearchCtrl = new FormControl<string>('');

    language = 'de';
    vkorg = '0500';

    constructor(
        private backend: BackendService,
        private userData: UserDataService,
        private snackBar: MatSnackBar,
        private translationService: TranslateService,
    ) {
        this.userData.language$.subscribe((val) => (this.language = val));
        this.userData.catalog$.subscribe((val) => {
            if (this.vkorg !== val) {
                this.clearData();
            }
            this.vkorg = val;
        });
    }

    clearData() {
        this.results.emit([]);
    }

    search() {
        this.clearData();
        this.backend
            .getSalesReceiptSearch(this.salesSearchCtrl.value ?? '', this.vkorg, this.language)
            .pipe(
                catchError(() => {
                    this.snackBar.open(
                        this.translationService.instant('errors.sales_receipt_not_found', {
                            searchString: this.salesSearchCtrl.value || '',
                        }),
                        this.translationService.instant('errors.ok'),
                    );
                    return of(null);
                }),
            )
            .subscribe((response) => {
                if (response) {
                    this.results.emit(response.items);
                }
            });
    }
}
