import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BackendService, CatalogItem } from './backend.service';
import { EverestBackendService } from './everest-backend.service';

@Injectable({
    providedIn: 'root',
})
export class UserDataService {
    private catalogs: CatalogItem[] = [];
    private _catalog$: BehaviorSubject<string> = new BehaviorSubject(DEFAULT_VKORG);
    private _language$: BehaviorSubject<string> = new BehaviorSubject(DEFAULT_LANGUAGE);
    public readonly catalog$: Observable<string> = this._catalog$.asObservable();
    public readonly language$: Observable<string> = this._language$.asObservable();

    constructor(
        private backend: BackendService,
        private everestBackend: EverestBackendService,
        private translationService: TranslateService,
    ) {
        this.translationService.use(DEFAULT_LANGUAGE);
    }

    init() {
        return forkJoin({
            catalog: this.backend.getCatalog(),
            me: this.backend.getMe(),
        }).pipe(
            switchMap((result) => {
                this.catalogs = result.catalog;
                const userIdentityType = result.me.properties?.find((p) => p.name === 'IdentityType');
                if (userIdentityType && ['ViInt', 'ViExt', 'ViAcc'].includes(userIdentityType?.value)) {
                    return this.everestBackend.getEmployee().pipe(
                        tap((res) => {
                            const vkorgLanguageMappings = VKORG_MAPPINGS.filter((mapping) => {
                                return res.salesFunctions.some(
                                    (languageCode) => languageCode.salesOrg === mapping.vkorg,
                                );
                            });
                            this.setMatchingVkorgMapping(vkorgLanguageMappings);
                        }),
                    );
                } else {
                    return this.everestBackend.getCustomer(result.me.company.id).pipe(
                        tap((res) => {
                            const vkorgLanguageMappings = VKORG_MAPPINGS.filter(
                                (item) => item.vkorg === res.primarySalesOrg,
                            );
                            this.setMatchingVkorgMapping(vkorgLanguageMappings);
                        }),
                    );
                }
            }),
        );
    }

    setMatchingVkorgMapping(vkorgLanguageMappings: VkorgMapping[]) {
        const matchingVkorgMapping =
            vkorgLanguageMappings.length > 1 ? this.bestLocaleMatch(vkorgLanguageMappings) : vkorgLanguageMappings[0];
        const catalog = this.getCatalog(matchingVkorgMapping?.vkorg ?? DEFAULT_VKORG);
        this.setCatalogAndLanguage(
            catalog?.vkorg ?? DEFAULT_VKORG,
            matchingVkorgMapping ? matchingVkorgMapping.locale : this.getBrowserLang(),
        );
    }

    bestLocaleMatch(vkorgLanguageMappings: VkorgMapping[]) {
        const locale = this.translationService.getBrowserCultureLang() || 'de-DE';
        const matchingLocale = vkorgLanguageMappings.find((mapping) => mapping.locale.startsWith(locale.split('-')[0]));
        return matchingLocale ?? vkorgLanguageMappings[0];
    }

    getBrowserLang() {
        const supportedLanguage = this.translationService.getBrowserLang();
        return supportedLanguage ?? DEFAULT_LANGUAGE;
    }

    getCatalog(vkorg: string) {
        return this.catalogs.find((c) => c.vkorg == vkorg);
    }

    setCatalogAndLanguage(catalog: string, language: string) {
        this._catalog$.next(catalog);
        this._language$.next(language);
        if (language.includes('-')) {
            this.translationService.use(language);
        } else {
            const matchingLocale = VKORG_MAPPINGS.filter((mapping) => mapping.locale.startsWith(language));
            if (matchingLocale.length > 1) {
                const matchingLocaleMultipleLanguages = matchingLocale.find((mapping) => mapping.vkorg === catalog);
                this.translationService.use(matchingLocaleMultipleLanguages?.locale ?? DEFAULT_LANGUAGE);
            } else {
                this.translationService.use(matchingLocale[0].locale ?? DEFAULT_LANGUAGE);
            }
        }
    }
}

interface VkorgMapping {
    languageCode: string;
    vkorg: string;
    locale: string;
}

const DEFAULT_VKORG = '0500';
const DEFAULT_LANGUAGE = 'de-DE';
const VKORG_MAPPINGS = [
    {
        languageCode: 'DE',
        vkorg: '0500',
        locale: 'de-DE',
    },
    {
        languageCode: 'DE',
        vkorg: '0500',
        locale: 'en-DE',
    },
    {
        languageCode: 'FR',
        vkorg: '0600',
        locale: 'fr-FR',
    },
    {
        languageCode: 'BG',
        vkorg: '7800',
        locale: 'bg-BG',
    },
    {
        languageCode: 'CZ',
        vkorg: '9700',
        locale: 'cs-CZ',
    },
    {
        languageCode: 'DK',
        vkorg: '6600',
        locale: 'da-DK',
    },
    {
        languageCode: 'AT',
        vkorg: '5000',
        locale: 'de-AT',
    },
    {
        languageCode: 'LU',
        vkorg: '6100',
        locale: 'de-LU',
    },
    {
        languageCode: 'ES',
        vkorg: '7100',
        locale: 'es-ES',
    },
    {
        languageCode: 'EE',
        vkorg: '8700',
        locale: 'et-EE',
    },
    {
        languageCode: 'FI',
        vkorg: '8800',
        locale: 'fi-FI',
    },
    {
        languageCode: 'BE',
        vkorg: '6000',
        locale: 'fr-BE',
    },
    {
        languageCode: 'LU',
        vkorg: '6100',
        locale: 'fr-LU',
    },
    {
        languageCode: 'HR',
        vkorg: '7600',
        locale: 'hr-HR',
    },
    {
        languageCode: 'HU',
        vkorg: '9800',
        locale: 'hu-HU',
    },
    {
        languageCode: 'IT',
        vkorg: '7300',
        locale: 'it-IT',
    },
    {
        languageCode: 'LT',
        vkorg: '8700',
        locale: 'lt-LT',
    },
    {
        languageCode: 'LV',
        vkorg: '8900',
        locale: 'lv-LV',
    },
    {
        languageCode: 'BE',
        vkorg: '6000',
        locale: 'nl-BE',
    },
    {
        languageCode: 'NL',
        vkorg: '5900',
        locale: 'nl-NL',
    },
    {
        languageCode: 'PL',
        vkorg: '9500',
        locale: 'pl-PL',
    },
    {
        languageCode: 'RO',
        vkorg: '7700',
        locale: 'ro-RO',
    },
    {
        languageCode: 'SK',
        vkorg: '7900',
        locale: 'sk-SK',
    },
    {
        languageCode: 'SI',
        vkorg: '7500',
        locale: 'sl-SI',
    },
    {
        languageCode: 'SE',
        vkorg: '9600',
        locale: 'sv-SE',
    },
    {
        languageCode: 'TR',
        vkorg: '8000',
        locale: 'tr-TR',
    },
    {
        languageCode: 'GB',
        vkorg: '6800',
        locale: 'en-GB',
    },
    {
        languageCode: 'RS',
        vkorg: '7400',
        locale: 'sr-RS',
    },
];
