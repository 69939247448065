<form>
    <mat-form-field appearance="outline">
        <mat-label>{{ 'content.productSearch.inputLabel' | translate }}</mat-label>
        <input matInput [matAutocomplete]="auto" [formControl]="stateCtrl" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let order of orders | async" [value]="order">
                <div fxLayout="row">
                    <div [innerHTML]="order.id | highlight: toHighlight"></div>
                    <div class="result" [innerHTML]="order.shortDescription | highlight: toHighlight"></div>
                </div>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>
