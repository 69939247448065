import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { CatalogItem } from '../../../services/backend.service';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import {
    ControlUnitAccessoriesSpecifics,
    CustomProduct,
    EverestBackendService,
    HeatingBoilerSpecifics,
    HeatingCylinderSpecifics,
    HeatPumpSpecifics,
    SolarCircuitDistributionSpecifics,
    SolarCollectorSpecifics,
} from '../../../services/everest-backend.service';
import { PRODUCT_CATEGORIES, ProductEnumValue } from '../products';

@Component({
    selector: 'app-create-product',
    templateUrl: './create-product.component.html',
    styleUrls: ['./create-product.component.scss'],
})
export class CreateProductComponent {
    mainFormGroup!: UntypedFormGroup;
    controlUnitAccessoriesFormGroup!: UntypedFormGroup;
    heatingCylinderFormGroup!: UntypedFormGroup;
    solarCircuitFormGroup!: UntypedFormGroup;
    solarCollectorFormGroup!: UntypedFormGroup;
    heatPumpFormGroup!: UntypedFormGroup;
    heatingBoilerFormGroup!: UntypedFormGroup;
    creatingProduct = false;
    editingId?: string;
    productCategories = PRODUCT_CATEGORIES.map((p) => {
        return {
            ...p,
            viewValue: this.translationService.instant(p.viewValue),
        };
    });

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            customProduct: CustomProduct | undefined;
            catalog: CatalogItem;
        },
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<CreateProductComponent>,
        private translationService: TranslateService,
        private everestBackend: EverestBackendService,
        private errorHandlerService: ErrorHandlerService,
    ) {
        this.mainFormGroup = this.fb.group({
            productDescriptionControl: ['', Validators.required],
            productCategoryControl: ['', Validators.required],
        });

        this.controlUnitAccessoriesFormGroup = this.fb.group({
            temperatureControllerClassificationControl: ['', Validators.required],
        });

        this.heatingCylinderFormGroup = this.fb.group({
            cylinderVolumeControl: ['', Validators.required],
            heatLossesControl: ['', Validators.required],
            nonSolarCylinderVolumeControl: ['', Validators.required],
        });

        this.solarCircuitFormGroup = this.fb.group({
            pumpPowerConsumptionControl: ['', Validators.required],
            pumpStandByPowerControl: ['', Validators.required],
        });

        this.solarCollectorFormGroup = this.fb.group({
            collectorSizeControl: ['', Validators.required],
            collectorEfficiencyControl: ['', Validators.required],
            linearHeatTransferCoefficientControl: ['', Validators.required],
            collectorOpticalEfficiencyControl: ['', Validators.required],
            angleCorrectionFactorControl: ['', Validators.required],
            squareHeatTransferCoefficientControl: ['', Validators.required],
            pumpPowerConsumptionControl: [''],
            pumpStandByPowerControl: [''],
        });

        this.heatingBoilerFormGroup = this.fb.group({
            seasonalSpaceHeatingEnergyEfficiencyControl: ['', Validators.required],
            nominalHeatOutputControl: ['', Validators.required],
            temperatureControllerClassificationControl: ['', Validators.required],
        });
        this.heatPumpFormGroup = this.fb.group({
            seasonalSpaceHeatingEnergyEfficiencyControl: ['', Validators.required],
            nominalHeatOutputControl: ['', Validators.required],
            temperatureControllerClassificationControl: ['', Validators.required],
        });

        this.mainFormGroup.get('productCategoryControl')?.valueChanges.subscribe((value) => {
            if (value.typeId === '13' || value.typeId === '8') {
                this.heatingCylinderFormGroup.get('nonSolarCylinderVolumeControl')?.setValidators(Validators.required);
            } else {
                this.heatingCylinderFormGroup.get('nonSolarCylinderVolumeControl')?.clearValidators();
            }
        });

        this.productCategories = this.productCategories.filter((pc) =>
            data.catalog.customErpTypes.includes(Number(pc.typeId)),
        );

        if (data.customProduct) {
            this.setEditedItem(data.customProduct);
        }
    }

    private validateFormGroup(formGroup?: UntypedFormGroup) {
        formGroup?.markAllAsTouched();

        if (!formGroup || formGroup?.invalid) {
            this.creatingProduct = false;
        }
    }

    public createProduct() {
        this.creatingProduct = true;
        this.mainFormGroup.markAllAsTouched();

        this.validateFormGroup(this.mainFormGroup);

        this.validateProperSubform();
        if (!this.creatingProduct) {
            return;
        }

        if (this.editingId) {
            this.everestBackend
                .updateProduct(this.createProductPayload())
                .pipe(catchError(this.errorHandlerService.handleGenericError))
                .subscribe(this.handleResponse);
        } else {
            this.everestBackend
                .createProduct(this.createProductPayload())
                .pipe(catchError(this.errorHandlerService.handleGenericError))
                .subscribe(this.handleResponse);
        }
    }

    private createProductPayload() {
        return {
            _id: this.editingId,
            typeId: this.mainFormGroup.get('productCategoryControl')?.value.typeId,
            specifics: {
                PRODUKTNAME: this.mainFormGroup.get('productDescriptionControl')?.value,
                ...this.getSpecifics(),
            },
        };
    }

    handleResponse = (res: null | CustomProduct) => {
        if (res) {
            this.dialogRef.close(res);
        }
    };

    private validateProperSubform() {
        switch (this.mainFormGroup.get('productCategoryControl')?.value.typeId) {
            case '9': {
                this.validateFormGroup(this.controlUnitAccessoriesFormGroup);
                break;
            }
            case '18': {
                this.validateFormGroup(this.solarCircuitFormGroup);
                break;
            }
            case '6': {
                this.validateFormGroup(this.solarCollectorFormGroup);
                break;
            }
            case '5':
            case '8':
            case '13': {
                this.validateFormGroup(this.heatingCylinderFormGroup);
                break;
            }
            case '3': {
                this.validateFormGroup(this.heatPumpFormGroup);
                break;
            }
            case '1': {
                this.validateFormGroup(this.heatingBoilerFormGroup);
                break;
            }
            default: {
                break;
            }
        }
    }

    private setEditedItem(product: CustomProduct) {
        this.editingId = product._id;
        this.mainFormGroup.get('productDescriptionControl')?.setValue(product.specifics.PRODUKTNAME);
        this.mainFormGroup
            .get('productCategoryControl')
            ?.setValue(this.productCategories.find((p) => p.typeId === product.typeId));

        switch (product.typeId) {
            case '9': {
                const specifics = <ControlUnitAccessoriesSpecifics>product.specifics;
                this.controlUnitAccessoriesFormGroup
                    .get('temperatureControllerClassificationControl')
                    ?.setValue(specifics.E_REG_KLASSE_ROM);
                break;
            }
            case '18': {
                const specifics = <SolarCircuitDistributionSpecifics>product.specifics;
                this.solarCircuitFormGroup.get('pumpPowerConsumptionControl')?.setValue(specifics.LEI_AUF1);
                this.solarCircuitFormGroup.get('pumpStandByPowerControl')?.setValue(specifics.LEIST_AUFNAHME_PUMPE_BR);
                break;
            }
            case '6': {
                const specifics = <SolarCollectorSpecifics>product.specifics;
                this.solarCollectorFormGroup.get('collectorSizeControl')?.setValue(specifics.APERTURFLAECHE);
                this.solarCollectorFormGroup.get('collectorOpticalEfficiencyControl')?.setValue(specifics.E_ETA0);
                this.solarCollectorFormGroup.get('linearHeatTransferCoefficientControl')?.setValue(specifics.E_K1);
                this.solarCollectorFormGroup.get('squareHeatTransferCoefficientControl')?.setValue(specifics.E_K2);
                this.solarCollectorFormGroup.get('collectorEfficiencyControl')?.setValue(specifics.E_WIRKUNGSGRAD_KOLL);
                this.solarCollectorFormGroup
                    .get('pumpStandByPowerControl')
                    ?.setValue(specifics.LEIST_AUFNAHME_PUMPE_BR);
                this.solarCollectorFormGroup.get('pumpPowerConsumptionControl')?.setValue(specifics.LEI_AUF1);
                this.solarCollectorFormGroup.get('angleCorrectionFactorControl')?.setValue(specifics.WINKELKORREKTUR);
                break;
            }
            case '5':
            case '8':
            case '13': {
                const specifics = <HeatingCylinderSpecifics>product.specifics;
                this.heatingCylinderFormGroup.get('cylinderVolumeControl')?.setValue(specifics.SPEICHERINHALT);
                this.heatingCylinderFormGroup.get('heatLossesControl')?.setValue(specifics.E_WARMHALTEVERLUSTE);
                this.heatingCylinderFormGroup
                    .get('nonSolarCylinderVolumeControl')
                    ?.setValue(specifics.NICHTSOLARES_SPEICHERVOL);
                break;
            }
            case '3': {
                const specifics = <HeatPumpSpecifics>product.specifics;
                this.heatPumpFormGroup
                    .get('temperatureControllerClassificationControl')
                    ?.setValue(specifics.E_REG_KLASSE_ROM);
                this.heatPumpFormGroup.get('nominalHeatOutputControl')?.setValue(specifics.NENNLEISTUNG_WP_MT);
                this.heatPumpFormGroup
                    .get('seasonalSpaceHeatingEnergyEfficiencyControl')
                    ?.setValue(specifics.WIRKUNGSGRAD_ETA_S_MT);
                break;
            }
            case '1': {
                const specifics = <HeatingBoilerSpecifics>product.specifics;
                this.heatingBoilerFormGroup
                    .get('temperatureControllerClassificationControl')
                    ?.setValue(specifics.E_REG_KLASSE_ROM);
                this.heatingBoilerFormGroup.get('nominalHeatOutputControl')?.setValue(specifics.P_RATED);
                this.heatingBoilerFormGroup
                    .get('seasonalSpaceHeatingEnergyEfficiencyControl')
                    ?.setValue(specifics.WIRKUNGSGRAD_ETA_S);
                break;
            }
            default: {
                throw new Error('Not supported');
            }
        }
    }

    private getSpecifics() {
        switch (this.mainFormGroup.get('productCategoryControl')?.value.typeId) {
            case '9': {
                return {
                    E_REG_KLASSE_ROM: this.controlUnitAccessoriesFormGroup.get(
                        'temperatureControllerClassificationControl',
                    )?.value,
                };
            }
            case '18': {
                return {
                    LEI_AUF1: this.solarCircuitFormGroup.get('pumpPowerConsumptionControl')?.value,
                    LEIST_AUFNAHME_PUMPE_BR: this.solarCircuitFormGroup.get('pumpStandByPowerControl')?.value,
                };
            }
            case '6': {
                return {
                    APERTURFLAECHE: this.solarCollectorFormGroup.get('collectorSizeControl')?.value,
                    E_ETA0: this.solarCollectorFormGroup.get('collectorOpticalEfficiencyControl')?.value,
                    E_K1: this.solarCollectorFormGroup.get('linearHeatTransferCoefficientControl')?.value,
                    E_K2: this.solarCollectorFormGroup.get('squareHeatTransferCoefficientControl')?.value,
                    E_WIRKUNGSGRAD_KOLL: this.solarCollectorFormGroup.get('collectorEfficiencyControl')?.value,
                    LEIST_AUFNAHME_PUMPE_BR: this.solarCollectorFormGroup.get('pumpStandByPowerControl')?.value,
                    LEI_AUF1: this.solarCollectorFormGroup.get('pumpPowerConsumptionControl')?.value,
                    WINKELKORREKTUR: this.solarCollectorFormGroup.get('angleCorrectionFactorControl')?.value,
                };
            }
            case '5': {
                return {
                    SPEICHERINHALT: this.heatingCylinderFormGroup.get('cylinderVolumeControl')?.value,
                    E_WARMHALTEVERLUSTE: this.heatingCylinderFormGroup.get('heatLossesControl')?.value,
                };
            }
            case '8':
            case '13': {
                return {
                    SPEICHERINHALT: this.heatingCylinderFormGroup.get('cylinderVolumeControl')?.value,
                    E_WARMHALTEVERLUSTE: this.heatingCylinderFormGroup.get('heatLossesControl')?.value,
                    NICHTSOLARES_SPEICHERVOL: this.heatingCylinderFormGroup.get('nonSolarCylinderVolumeControl')?.value,
                };
            }
            case '3': {
                return {
                    E_REG_KLASSE_ROM: this.heatPumpFormGroup.get('temperatureControllerClassificationControl')?.value,
                    NENNLEISTUNG_WP_MT: this.heatPumpFormGroup.get('nominalHeatOutputControl')?.value,
                    WIRKUNGSGRAD_ETA_S_MT: this.heatPumpFormGroup.get('seasonalSpaceHeatingEnergyEfficiencyControl')
                        ?.value,
                };
            }
            case '1': {
                return {
                    E_REG_KLASSE_ROM: this.heatingBoilerFormGroup.get('temperatureControllerClassificationControl')
                        ?.value,
                    P_RATED: this.heatingBoilerFormGroup.get('nominalHeatOutputControl')?.value,
                    WIRKUNGSGRAD_ETA_S: this.heatingBoilerFormGroup.get('seasonalSpaceHeatingEnergyEfficiencyControl')
                        ?.value,
                };
            }
            default: {
                throw new Error('Not supported');
            }
        }
    }

    classificationsOfTemperatureControllers: ProductEnumValue[] = [
        {
            viewValue: this.translationService.instant('create_product.classificationOfTemperatureController.classI'),
            value: 'I',
        },
        {
            viewValue: this.translationService.instant('create_product.classificationOfTemperatureController.classII'),
            value: 'II',
        },
        {
            viewValue: this.translationService.instant('create_product.classificationOfTemperatureController.classIII'),
            value: 'III',
        },
        {
            viewValue: this.translationService.instant('create_product.classificationOfTemperatureController.classIV'),
            value: 'IV',
        },
        {
            viewValue: this.translationService.instant('create_product.classificationOfTemperatureController.classV'),
            value: 'V',
        },
        {
            viewValue: this.translationService.instant('create_product.classificationOfTemperatureController.classVI'),
            value: 'VI',
        },
        {
            viewValue: this.translationService.instant('create_product.classificationOfTemperatureController.classVII'),
            value: 'VII',
        },
        {
            viewValue: this.translationService.instant(
                'create_product.classificationOfTemperatureController.classVIII',
            ),
            value: 'VIII',
        },
    ];
}
