import { Component, Input } from '@angular/core';
import { ProductElement } from '../../../../services/backend.service';

@Component({
    selector: 'app-download-documents-menu',
    templateUrl: './download-documents-menu.component.html',
    styleUrls: ['./download-documents-menu.component.scss'],
})
export class DownloadDocumentsMenuComponent {
    @Input() element: ProductElement;

    public showDownloadDocuments = () => {
        return this.element.documents && this.element.documents.filter((d) => d.url && d.url.length > 0).length > 0;
    };
}
