import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService {
    constructor(
        private snackBar: MatSnackBar,
        private translationService: TranslateService,
    ) {}

    handleGenericError = () => {
        this.snackBar.open(
            this.translationService.instant('errors.backend'),
            this.translationService.instant('errors.ok'),
        );
        return of(null);
    };
}
