<h2 mat-dialog-title>{{ data.shortDescription }} ({{ data.id }})</h2>
<mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>{{ 'change_quantity.enter_number' | translate }}</mat-label>
        <input
            matInput
            type="number"
            required
            min="1"
            [(ngModel)]="dialogValue"
            #quantity="ngModel"
            placeholder="{{ 'change_quantity.enter_number' | translate }}"
        />
        <span matTextSuffix>{{ 'change_quantity.pieces' | translate }}</span>
    </mat-form-field>

    <div *ngIf="quantity.invalid" class="alert">
        <div *ngIf="quantity.errors?.required">
            {{ 'errors.field_is_required' | translate }}
        </div>
        <div *ngIf="quantity.errors?.min">
            {{ 'change_quantity.error_min' | translate }}
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button
        mat-button
        [mat-dialog-close]="dialogValue"
        cdkFocusInitial
        [disabled]="quantity.errors?.required || quantity.errors?.min"
    >
        {{ 'change_quantity.ok' | translate }}
    </button>
    <button mat-button mat-dialog-close>{{ 'change_quantity.cancel' | translate }}</button>
</mat-dialog-actions>
