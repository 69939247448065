import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StatusPageService } from './maintenance/services/status-page.service';
import { AuthService } from './services/auth.service';
import { InstanaService } from './services/instana.service';
import { UserDataService } from './services/user-data.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'fe-energy-label';
    downtime = true;

    constructor(
        private instana: InstanaService,
        public auth: AuthService,
        private catalog: UserDataService,
        private snackBar: MatSnackBar,
        private translationService: TranslateService,
        private statusPageService: StatusPageService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.auth.init();
        this.catalog.init().subscribe(
            () => {},
            () => {
                this.snackBar.open(
                    this.translationService.instant('errors.backend'),
                    this.translationService.instant('errors.ok'),
                );
            },
        );
        this.statusPageService
            .checkForDowntime()
            .pipe()
            .subscribe((downtime) => (this.downtime = downtime));
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.instana.reportPage(val.urlAfterRedirects);
            }
        });
    }
}
