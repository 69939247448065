<h2 mat-dialog-title>{{ data.shortDescription }} ({{ data.id }})</h2>
<mat-dialog-content>
    <label>{{ 'change_room_hookup.with_room_hook_up_question' | translate }}</label>
    <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="change-room-hookup-radio-group"
        [(ngModel)]="dialogValue"
    >
        <mat-radio-button class="change-room-hookup-radio-button" [value]="true">
            {{ 'change_room_hookup.yes' | translate }}
        </mat-radio-button>
        <mat-radio-button class="change-room-hookup-radio-button" [value]="false">
            {{ 'change_room_hookup.no' | translate }}
        </mat-radio-button>
    </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="dialogValue" cdkFocusInitial [disabled]="dialogValue === null">
        {{ 'change_room_hookup.ok' | translate }}
    </button>
    <button mat-button mat-dialog-close>{{ 'change_room_hookup.cancel' | translate }}</button>
</mat-dialog-actions>
