import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GlobalHeaderModule } from '@vi/global-header';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BodyComponent } from './body/body.component';
import { ContentComponent } from './body/content/content.component';
import { LabelDialogComponent } from './body/content/label-dialog/label-dialog.component';
import { ProductSearchComponent } from './body/content/product-search/product-search.component';
import { ChangeQuantityComponent } from './body/content/product-table/change-quantity/change-quantity.component';
import { ChangeRoomHookupComponent } from './body/content/product-table/change-room-hookup/change-room-hookup.component';
import { ChangeUsageComponent } from './body/content/product-table/change-usage/change-usage.component';
import { DownloadDocumentsMenuComponent } from './body/content/product-table/download-documents-menu/download-documents-menu.component';
import { HeatGeneratorComponent } from './body/content/product-table/heat-generator/heat-generator.component';
import { MessageDialogComponent } from './body/content/product-table/message-dialog/message-dialog.component';
import { ProductTableComponent } from './body/content/product-table/product-table.component';
import { RepeatableProductComponent } from './body/content/product-table/repeatable-product/repeatable-product.component';
import { SalesReceiptComponent } from './body/content/sales-receipt/sales-receipt.component';
import { SalesReceiptResultsComponent } from './body/content/sales-receipt-results/sales-receipt-results.component';
import { CreateProductComponent } from './body/menu/create-product/create-product.component';
import { MenuComponent } from './body/menu/menu.component';
import { SelectCatalogComponent } from './body/menu/select-catalog/select-catalog.component';
import { SelectProductComponent } from './body/menu/select-product/select-product.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { InstanaErrorHandler } from './instana-error-handler';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { HighlightPipe } from './pipes/highligh.pipe';
import { HttpAuthInterceptorService } from './services/http-auth-interceptor.service';
import { environment } from 'src/environments/environment';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { DswFooterComponent } from '@vi-succ/design-system-web';

export function createTranslateLoader(http: HttpBackend) {
    return new MultiTranslateHttpLoader(http, [
        {
            prefix: 'https://api-integration.viessmann.com/translations/v2/phraseapp-proxy/projects/89e6404bb2ed22771540cfa66647a3d5/locales/',
            suffix: '/download?file_format=simple_json',
        },
        {
            prefix: 'https://api.viessmann.com/translations/v2/phraseapp-proxy/projects/ec38b083a23c58c604a49d7f443dd7f2/locales/',
            suffix: '/download?file_format=simple_json',
        },
    ]);
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        BodyComponent,
        ContentComponent,
        FooterComponent,
        MenuComponent,
        HighlightPipe,
        ProductSearchComponent,
        SalesReceiptComponent,
        SalesReceiptResultsComponent,
        ProductTableComponent,
        SelectProductComponent,
        CreateProductComponent,
        ChangeQuantityComponent,
        SelectCatalogComponent,
        HeatGeneratorComponent,
        DownloadDocumentsMenuComponent,
        RepeatableProductComponent,
        ChangeUsageComponent,
        LabelDialogComponent,
        MessageDialogComponent,
        ChangeRoomHookupComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        GlobalHeaderModule.forRoot({
            appId: environment.auth.appId,
            baseUrl: environment.auth.baseUrl,
            accountAppUrl: environment.auth.registerBaseUrl,
            partnerPortalBaseUrl: <any>undefined,
        }),
        MaintenanceModule,
        FlexModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatTreeModule,
        MatDialogModule,
        MatSnackBarModule,
        MatRippleModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatTooltipModule,
        MatMenuModule,
        MatRadioModule,
        MatCardModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en-GB',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpBackend],
            },
        }),
        CommonModule,
        DswFooterComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpAuthInterceptorService,
            multi: true,
        },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { minWidth: '720px', hasBackdrop: true } },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
        {
            provide: ErrorHandler,
            useClass: InstanaErrorHandler,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
