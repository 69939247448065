import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class InstanaService {
    constructor() {
        this.startSessionTracking();
    }

    private static invokeIneum(func: () => void) {
        if (environment.instana && typeof ineum === 'function') {
            func();
        }
    }

    startSessionTracking() {
        // eslint-disable-next-line no-undef
        InstanaService.invokeIneum(() => ineum('trackSessions'));
    }

    reportUser(userId: string, userName: string, userEmail: string) {
        // eslint-disable-next-line no-undef
        InstanaService.invokeIneum(() => ineum('user', userId, userName, userEmail));
    }

    reportPage(pageName: string) {
        // eslint-disable-next-line no-undef
        InstanaService.invokeIneum(() => ineum('page', pageName));
    }

    reportError(
        error: string | Error,
        meta?: {
            [key: string]: string | number | boolean;
        },
    ) {
        // eslint-disable-next-line no-undef
        InstanaService.invokeIneum(() => ineum('reportError', error, { meta }));
    }

    reportMetadataObject(obj: { [key: string]: string | number | boolean }) {
        for (const [key, value] of Object.entries(obj)) {
            this.reportMetadata(key, value);
        }
    }

    reportMetadata(key: string, value: string | number | boolean) {
        // eslint-disable-next-line no-undef
        InstanaService.invokeIneum(() => ineum('meta', key, value));
    }
}
