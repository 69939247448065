import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductElement, SOLAR_COLLECTOR_USAGES } from '../../../../services/backend.service';

@Component({
    selector: 'app-change-usage',
    templateUrl: './change-usage.component.html',
    styleUrls: ['./change-usage.component.scss'],
})
export class ChangeUsageComponent {
    usages = SOLAR_COLLECTOR_USAGES;

    dialogValue?: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: ProductElement) {
        this.dialogValue = data.config.usage;
    }
}
