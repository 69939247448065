import { NestedTreeControl } from '@angular/cdk/tree';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { first } from 'rxjs/operators';
import { EverestBackendService } from '../../services/everest-backend.service';
import { MenuService } from '../../services/menu.service';
import { ProductService } from '../../services/product.service';
import { CreateProductComponent } from './create-product/create-product.component';
import { SelectCatalogComponent } from './select-catalog/select-catalog.component';
import { SelectProductComponent } from './select-product/select-product.component';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
    vkorg: string;
    language: string;
    TREE_DATA: MenuNode[] = [
        {
            name: 'menu.own_product_brochure',
            children: [
                {
                    name: 'menu.create_product',
                    click: () => {
                        this.openCreateProduct();
                    },
                },
                {
                    name: 'menu.select_product',
                    click: () => {
                        this.openSelectProduct();
                    },
                },
                {
                    name: 'menu.administer_product',
                    click: () => {
                        this.openAdministerProduct();
                    },
                },
            ],
        },
        {
            name: 'menu.viessmann_product_brochure',
            children: [
                {
                    name: 'menu.change_brochure_or_language',
                    click: () => {
                        this.openChangeCatalog();
                    },
                },
            ],
        },
    ];

    treeControl = new NestedTreeControl<MenuNode>((node) => node.children);
    dataSource = new MatTreeNestedDataSource<MenuNode>();

    hasChild = (_: number, node: MenuNode) => !!node.children && node.children.length > 0;

    constructor(
        private menuService: MenuService,
        private userData: UserDataService,
        private dialog: MatDialog,
        private productService: ProductService,
        private everestBackend: EverestBackendService,
    ) {
        this.userData.language$.subscribe((val) => (this.language = val));
        this.userData.catalog$.subscribe((val) => (this.vkorg = val));
        this.dataSource.data = this.TREE_DATA;
    }

    closeMenu() {
        this.menuService.close();
    }

    openSelectProduct() {
        const selectProductDialog = this.dialog.open(SelectProductComponent, {
            data: {
                mode: 'select',
            },
        });

        selectProductDialog.afterClosed().subscribe((result) => {
            if (result) {
                this.productService.addCustomProduct.next(result);
            }
        });
    }

    openAdministerProduct() {
        this.userData.catalog$.pipe(first()).subscribe((vkorg) => {
            const administerProductDialog = this.dialog.open(SelectProductComponent, {
                data: {
                    mode: 'admin',
                },
            });

            administerProductDialog.afterClosed().subscribe((result) => {
                if (result) {
                    const editDialog = this.dialog.open(CreateProductComponent, {
                        data: {
                            customProduct: result,
                            catalog: this.userData.getCatalog(vkorg),
                        },
                    });

                    editDialog.afterClosed().subscribe(() => {
                        this.openAdministerProduct();
                    });
                }
            });
        });
    }

    openCreateProduct() {
        this.userData.catalog$.pipe(first()).subscribe((vkorg) => {
            const createProductDialog = this.dialog.open(CreateProductComponent, {
                data: {
                    catalog: this.userData.getCatalog(vkorg),
                },
            });

            createProductDialog.afterClosed().subscribe((result) => {
                if (result) {
                    this.everestBackend.getProduct(result).subscribe((product) => {
                        this.productService.addCustomProduct.next(product);
                    });
                }
            });
        });
    }

    openChangeCatalog() {
        const selectProductDialog = this.dialog.open(SelectCatalogComponent, {
            data: { vkorg: this.vkorg, language: this.language },
        });

        selectProductDialog.afterClosed().subscribe((result) => {
            if (result) {
                this.userData.setCatalogAndLanguage(result.catalog, result.language);
            }
        });
    }
}

interface MenuNode {
    name: string;
    children?: MenuNode[];
    click?(): void;
}
