<h2 mat-dialog-title>{{ 'select_catalog.title' | translate }}</h2>
<mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="center start">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <div *ngIf="!isLoading">
            <div fxLayout="row">
                <mat-form-field appearance="outline" class="me-1">
                    <mat-label>{{ 'select_catalog.catalog_dropdown' | translate }}</mat-label>
                    <mat-select [(ngModel)]="selectedCatalog" (selectionChange)="catalogChanged()">
                        <mat-option *ngFor="let catalog of catalogs" [value]="catalog">
                            {{ catalog.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'select_catalog.language_dropdown' | translate }}</mat-label>
                    <mat-select [(ngModel)]="selectedLang" [disabled]="!selectedCatalog">
                        <mat-option
                            *ngFor="let lang of selectedCatalog ? selectedCatalog.lang : emptyCatalog.lang"
                            [value]="lang"
                        >
                            {{ lang.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <span>{{ 'select_catalog.note' | translate }}</span>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        mat-button
        [mat-dialog-close]="{ catalog: selectedCatalog?.vkorg, language: selectedLang?.id }"
        [disabled]="!selectedLang"
    >
        {{ 'select_catalog.ok' | translate }}
    </button>
    <button mat-button mat-dialog-close cdkFocusInitial>{{ 'select_catalog.cancel' | translate }}</button>
</mat-dialog-actions>
