import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Product } from './backend.service';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    public addCustomProduct: Subject<Product>;

    constructor() {
        this.addCustomProduct = new Subject<Product>();
    }
}
