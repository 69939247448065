<div class="maintenance" fxLayout="column" *ngIf="visible">
    <div fxLayoutAlign="center center" *ngIf="partialOutage">
        <mat-icon>offline_bolt</mat-icon>
        <span [innerHTML]="'COMMON.MAINTENANCE.MESSAGE.PARTIAL' | translate"></span>
    </div>
    <div fxLayoutAlign="center center" *ngIf="maintenance">
        <mat-icon>construction</mat-icon>
        <span
            *ngIf="maintenance"
            [innerHTML]="
                'COMMON.MAINTENANCE.MESSAGE.PLANNED'
                    | translate
                        : {
                              from: maintenance.start_date | date: dateFormat,
                              until: maintenance.end_date | date: dateFormat
                          }
            "
        ></span>
    </div>
</div>
