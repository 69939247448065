import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductElement } from '../../../../services/backend.service';

@Component({
    selector: 'app-change-room-hookup',
    templateUrl: './change-room-hookup.component.html',
    styleUrls: ['./change-room-hookup.component.scss'],
})
export class ChangeRoomHookupComponent {
    dialogValue?: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: ProductElement) {
        this.dialogValue = data.config.room_hookup;
    }
}
