import { AfterContentInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BackendService, LabelUrls, Product, SalesReceipt } from '../../services/backend.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { EverestBackendService, Project } from '../../services/everest-backend.service';
import { UserDataService } from '../../services/user-data.service';
import { LabelDialogComponent } from './label-dialog/label-dialog.component';
import { ProductSearchComponent } from './product-search/product-search.component';
import { ProductTableComponent } from './product-table/product-table.component';
import { SalesReceiptComponent } from './sales-receipt/sales-receipt.component';
import { SalesReceiptResultsComponent } from './sales-receipt-results/sales-receipt-results.component';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements AfterContentInit {
    @ViewChild(ProductTableComponent) productTable: ProductTableComponent;
    @ViewChild(ProductSearchComponent) productSearch: ProductSearchComponent;
    @ViewChild(SalesReceiptResultsComponent) salesReceiptResults: SalesReceiptResultsComponent;
    @ViewChild(SalesReceiptComponent) salesReceipts: SalesReceiptComponent;

    displaySalesReceiptResults = false;
    dataSource: SalesReceipt[] = [];
    labels: LabelUrls | null = null;
    labelCallInProgress = false;
    language = 'de';
    vkorg = '0500';

    loading = true;
    projectId: string | null = null;
    project?: Project;
    callbackURL?: string;

    constructor(
        private backend: BackendService,
        private middleware: EverestBackendService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private translationService: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private userData: UserDataService,
        private errorHandlerService: ErrorHandlerService,
    ) {
        this.userData.language$.subscribe((val) => (this.language = val));
        this.userData.catalog$.subscribe((val) => (this.vkorg = val));
    }

    ngAfterContentInit() {
        this.route.params.pipe(untilDestroyed(this)).subscribe(async (params) => {
            this.projectId = params['projectId'];
            if (this.projectId) {
                await this.loadTemporaryProject(this.projectId);
            } else {
                this.loading = false;
            }
        });
    }

    async loadTemporaryProject(projectId: string) {
        try {
            const project = await this.middleware.getProject(projectId).toPromise();
            if (project?.mode.postData) {
                const postData = <ProjectPostData>JSON.parse(project.mode.postData);
                this.callbackURL = postData.callbackURL;
                this.project = project;
                if (postData.items.length > 0) {
                    this.addItems(postData.items);
                } else {
                    this.loading = false;
                }
            } else if (project?.mode.type === 'angebotsschreibung') {
                this.project = project;
                if (project.items.length > 0) {
                    this.addProducts(project.items);
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        } catch (err) {
            this.snackBar.open(
                this.translationService.instant('errors.projectLoadFailure'),
                this.translationService.instant('errors.ok'),
            );
            this.loading = false;
        }
    }

    addItems(items: Item[]) {
        const observables = items.map((item) =>
            this.backend
                .getProductDetails(item.materialNumber, this.vkorg, this.language)
                .pipe(catchError(this.errorHandlerService.handleGenericError)),
        );
        forkJoin(observables).subscribe((products) => {
            this.addProducts(products, items);
        });
    }

    addProducts(products: (Product | null)[], items: Item[] = []) {
        const errors: string[] = [];
        products.forEach((p, index) => {
            if (p) {
                const quantity = items.find((i) => i.materialNumber === p.id)?.quantity;
                if (Number.isInteger(quantity) && !Number.isInteger(p.elements[0].config.quantity)) {
                    p.elements[0].config.quantity = quantity;
                }

                const error = this.productTable.selectProduct(p, false);
                if (error) {
                    errors.push(error);
                }
            } else {
                errors.push(
                    this.translationService.instant('errors.item_not_found', { id: items[index]?.materialNumber }),
                );
            }
        });
        if (errors.length > 0) {
            this.snackBar.open(
                `${this.translationService.instant('errors.errors_in_batch_add', { count: errors.length })}`,
                this.translationService.instant('errors.ok'),
                {
                    duration: 0,
                },
            );
        }
        this.loading = false;
        if (this.salesReceiptResults) {
            this.salesReceiptResults.addingCallInProgress = false;
            this.salesReceipts.clearData();
        }
    }

    tabChanged(event: MatTabChangeEvent) {
        this.dataSource = [];
        this.displaySalesReceiptResults = event.index === 1;
    }

    selectProduct(event: Product) {
        this.productTable.selectProduct(event);
    }

    updateLabelButton(labels: LabelUrls | null) {
        this.labels = labels;
    }

    generateLabel() {
        if (this.labels) {
            this.labelCallInProgress = true;
            this.backend.callLabelUrl(this.labels?.imageUrl).subscribe(
                (p) => {
                    this.labelCallInProgress = false;
                    this.dialog.open(LabelDialogComponent, {
                        data: {
                            image: p,
                            labelUrls: this.labels,
                        },
                        height: '100%',
                        width: '55vh',
                    });
                },
                () => {
                    this.snackBar.open(
                        this.translationService.instant('errors.backend'),
                        this.translationService.instant('errors.ok'),
                    );
                },
            );
        }
    }

    showExternalAppButtons() {
        return this.router.url.includes('external') && this.projectId;
    }

    addProductsFromSalesReceipt(receiptItems: SalesReceipt[]) {
        this.addItems(
            receiptItems.map((receiptItem) => ({
                materialNumber: receiptItem.id,
            })),
        );
    }

    close() {
        window.open(this.callbackURL, '_self');
    }

    saveAndClose() {
        if (this.project && this.projectId) {
            const products = this.productTable.collectProducts();
            this.middleware
                .saveProject(this.projectId, {
                    name: this.project.name,
                    mode: {
                        type: this.project?.mode.type,
                    },
                    label: this.labels || {},
                    efficiency: this.productTable.efficiency || {},
                    items: products,
                    savedData: products,
                    config: {},
                    error: this.productTable.error,
                    countryId: this.vkorg,
                    langId: this.language,
                })
                .pipe(catchError(this.errorHandlerService.handleGenericError))
                .subscribe((p) => {
                    if (p) {
                        window.open(`${this.callbackURL}?projectId=${this.projectId}`, '_self');
                    }
                });
        }
    }
}

interface ProjectPostData {
    callbackURL: string;
    items: Item[];
}

interface Item {
    materialNumber: string;
    quantity?: number;
}
