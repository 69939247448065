import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BackendService {
    constructor(private http: HttpClient) {}

    getCatalog() {
        return this.http.get<CatalogItem[]>(`${environment.apiUrl}/energy-label/v1/catalogs`);
    }

    getMe() {
        return this.http.get<Me>(`${environment.apiUrl}/users/v1/users/me`, {
            params: { sections: ['sales', 'identity'] },
        });
    }

    getProductSearch(vkorg: string, language: string, query: string) {
        return this.http.get<ProductSearch>(`${environment.apiUrl}/energy-label/v1/${vkorg}/${language}/items`, {
            params: { from: '0', to: '100', query },
        });
    }

    getSalesReceiptSearch(orderNumber: string, vkorg: string, language: string) {
        return this.http.get<SalesReceiptSearch>(
            `${environment.apiUrl}/energy-label/v1/${vkorg}/${language}/orders/${orderNumber}`,
        );
    }

    getProductDetails(productId: string, vkorg: string, language: string) {
        return this.http.get<Product>(`${environment.apiUrl}/energy-label/v1/${vkorg}/${language}/items/${productId}`);
    }

    labels(products: Product[], vkorg: string, language: string) {
        return this.http.post<LabelUrls>(
            `${environment.apiUrl}/energy-label/v1/${vkorg}/${language}/labels`,
            this.productsToBody(products),
        );
    }

    callLabelUrl(url: string) {
        return this.http.get(`${environment.apiUrl}/energy-label/v1/${url}`, { responseType: 'blob' });
    }

    efficiency(products: Product[], vkorg: string, language: string) {
        return this.http.post<{
            efficiency: Efficiency;
        }>(`${environment.apiUrl}/energy-label/v1/${vkorg}/${language}/efficiency`, this.productsToBody(products));
    }

    productsToBody(products: Product[]) {
        return {
            force_sort: false,
            items: products.map((p) => ({
                id: (p.isCustom ? p._id : p.id) || '',
                isCustom: p.isCustom,
                typeId: p.type.id,
                elements: p.elements.map((e) => ({
                    id: (p.isCustom ? e._id : e.id) || '',
                    isCustom: p.isCustom,
                    config: e.config,
                    typeId: e.type.id,
                    specifics: e.specifics,
                })),
            })),
        };
    }
}

export const HEAT_GENERATOR_IDS = ['1', '2', '3', '10', '11', '16', '21', '22'];
export const HEAT_PUMPS = ['3', '11'];
export const CONTROL_UNIT_IDS = ['4'];
export const HEATING_WATER_BUFFER_CYLINDER_IDS = ['5'];
export const SOLAR_COLLECTOR_IDS = ['6'];
export const BURNER_IDS = ['7'];
export const DHW_CYLINDER_IDS = ['8'];
export const CONTROL_UNIT_ACCESSORIES_IDS = ['9'];
export const HEATING_WATER_BUFFER_CYLINDER_WITH_DHW_HEATING_IDS = ['13'];
export const SOLAR_CIRCUIT_DISTRIBUTION_IDS = ['18'];
export const DOMESTIC_VENTILATION_IDS = ['19'];
export const ELECTRIC_WATER_HEATER_IDS = ['26'];

export const SOLAR_COLLECTOR_USAGES = [
    {
        translationKey: 'solar_usage.heating_backup',
        id: '5',
    },
    {
        translationKey: 'solar_usage.dhw_heating',
        id: '8',
    },
    {
        translationKey: 'solar_usage.both',
        id: '5,8',
    },
];

/* eslint-disable camelcase */
export interface Efficiency {
    h_amount: number;
    h_class: string;
    h_primary_heater: string;
    h_reg_class: string;
    h_second_heater: string;
    w_amount: number;
    w_class: string;
    w_efficiency: string;
    w_reg_class: string;
    w_scale: string;
    h_next_efficiency: string;
    w_next_efficiency: string;
    h_next_class: string;
    w_next_class: string;
    w_solar: string;
    h_solar: string;
}
/* eslint-enable camelcase */

export interface Product {
    id: string;
    _id?: string;
    longDescription: string;
    shortDescription: string;
    picture: string;
    type: ProductType;
    elements: ProductElement[];
    isCustom?: boolean;
}

interface ProductType {
    id: string;
    name: string;
}

export interface ProductElement {
    id: string;
    _id?: string;
    longDescription: string;
    shortDescription: string;
    type: ProductType;
    specifics?: object;
    elements: [];
    config: {
        quantity?: number;
        primary: boolean | null;
        usage?: string;
        combi?: boolean | null;
        // eslint-disable-next-line camelcase
        room_hookup?: boolean;
    };
    documents: ProductDocument[];
}

interface ProductDocument {
    type: string;
    url: string;
}

export interface ProductSearch {
    items: Order[];
    maxCount: number;
}

export interface SalesReceiptSearch {
    items: SalesReceipt[];
    id: string;
}

export interface SalesReceipt {
    id: string;
    longDescription: string;
    shortDescription: string;
    type: ProductType;
    elements: [];
    config: {
        quantity: string;
    };
}

export interface Order {
    id: string;
    longDescription: string;
    shortDescription: string;
    picture: string;
}

export interface CatalogItem {
    vkorg: string;
    name: string;
    lang: LangItem[];
    customErpTypes: number[];
}

export interface LangItem {
    id: string;
    name: string;
}

export interface Me {
    address: {
        countryCode: string;
    };
    languageCode: string;
    company: {
        vkorg: string;
        countryCode: string;
        id: string;
    };
    properties?: {
        name: string;
        value: string;
    }[];
}

export interface LabelUrls {
    fullPdfUrl: string;
    fullPdfUrlInfo: string;
    imageUrl: string;
    localizedPdfUrl: string;
    pdfGescUrl: string;
    pdfUrl: string;
    pdfUrlInfo: string;
}

export interface HeatGeneratorElement {
    productElement: ProductElement;
    quantity?: number;
    roomHookup?: boolean;
}

export interface HeatGenerator {
    generator: Product;
    elements: HeatGeneratorElement[];
}
