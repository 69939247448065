import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService {
    constructor(private auth: AuthService) {}

    canActivate(): Observable<boolean> {
        return this.auth.getCsrfToken$().pipe(
            map((token) => {
                return !!token;
            }),
        );
    }
}
