<div [hidden]="loading" fxLayout="column" fxLayoutAlign="center center">
    <div *ngIf="showExternalAppButtons()" class="external-app-buttons">
        <button (click)="close()" color="primary" mat-raised-button>
            <mat-icon aria-hidden="false" aria-label="generate">close</mat-icon>
            {{ 'content.close' | translate }}
        </button>
        <button (click)="saveAndClose()" color="primary" mat-raised-button>
            <mat-icon aria-hidden="false" aria-label="generate">arrow_forward</mat-icon>
            {{ 'content.saveAndClose' | translate }}
        </button>
    </div>
    <div class="search">
        <div class="h3">{{ 'content.createLabel' | translate }}</div>
        <mat-tab-group
            (selectedTabChange)="tabChanged($event)"
            animationDuration="0ms"
            mat-align-tabs="start"
            mat-stretch-tabs="false"
            selectedIndex="0"
        >
            <mat-tab>
                <ng-template matTabLabel>
                    <span class="p-16 regular-text">{{ 'content.productSearchTitle' | translate }}</span>
                </ng-template>
                <ng-template matTabContent>
                    <app-product-search (selectedProduct)="selectProduct($event)"></app-product-search>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template matTabLabel>
                    <span class="p-16 regular-text">{{ 'content.salesReceiptTitle' | translate }}</span>
                </ng-template>
                <ng-template matTabContent>
                    <app-sales-receipt (results)="dataSource = $event"></app-sales-receipt>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
    <app-sales-receipt-results
        (addProducts)="addProductsFromSalesReceipt($event)"
        [dataSource]="dataSource"
    ></app-sales-receipt-results>
    <app-product-table (generatedLabel)="updateLabelButton($event)"></app-product-table>

    <div>
        <button
            (click)="generateLabel()"
            [class.btnspinner]="labelCallInProgress"
            [disabled]="!labels || labelCallInProgress"
            color="primary"
            mat-raised-button
        >
            <mat-icon aria-hidden="false" aria-label="generate">sync</mat-icon>
            {{ 'content.generateLabel' | translate }}
        </button>
    </div>
</div>

<div *ngIf="loading" class="loader-progress">
    <mat-progress-spinner class="spinner" mode="indeterminate"></mat-progress-spinner>
</div>
