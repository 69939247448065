<button
    *ngIf="showDownloadDocuments()"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    class="table-button"
    #trigger="matMenuTrigger"
>
    <mat-icon *ngIf="!trigger.menuOpen">expand_more</mat-icon>
    <mat-icon *ngIf="trigger.menuOpen">expand_less</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <a [href]="document.url" target="_blank" *ngFor="let document of element.documents" mat-menu-item>
        <mat-icon>file_download</mat-icon>
        <span>{{ 'table.download_' + document.type | translate }}</span>
    </a>
</mat-menu>
