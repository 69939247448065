<h2 mat-dialog-title>{{ 'content.labelDialog.title' | translate }}</h2>
<mat-dialog-content>
    <mat-card appearance="outlined" class="example-card mat-elevation-z0">
        <img mat-card-image class="energy-label" [src]="imageData" />
        <mat-card-content>
            <div class="outer-container">
                <div class="container">
                    <a [href]="pdfUrl" target="_blank" mat-raised-button color="primary">{{
                        'content.labelDialog.download' | translate
                    }}</a>
                </div>
                <div class="container2">
                    <p>
                        <mat-checkbox (change)="recalculate()" [(ngModel)]="attachProductLabelsAndDatasheets">{{
                            'content.labelDialog.attachProductLabelsAndDatasheets' | translate
                        }}</mat-checkbox>
                    </p>
                    <p>
                        <mat-checkbox (change)="recalculate()" [(ngModel)]="attachGeneralInfo">{{
                            'content.labelDialog.attachGeneralInfo' | translate
                        }}</mat-checkbox>
                    </p>
                </div>
            </div>
            <p class="time-warning">{{ timeWarning }}</p>
        </mat-card-content>
    </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>{{ 'content.labelDialog.close' | translate }}</button>
</mat-dialog-actions>
