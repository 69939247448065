import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    BURNER_IDS,
    CONTROL_UNIT_ACCESSORIES_IDS,
    CONTROL_UNIT_IDS,
    DHW_CYLINDER_IDS,
    HEAT_GENERATOR_IDS,
    HEAT_PUMPS,
    HeatGenerator,
    HeatGeneratorElement,
    HEATING_WATER_BUFFER_CYLINDER_IDS,
    HEATING_WATER_BUFFER_CYLINDER_WITH_DHW_HEATING_IDS,
    ProductElement,
} from '../../../../services/backend.service';
import { ProductEfficiency } from '../product-table.component';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-heat-generator]',
    templateUrl: './heat-generator.component.html',
    styleUrls: ['./heat-generator.component.scss'],
})
export class HeatGeneratorComponent {
    @Input() heatGenerator: HeatGenerator;
    @Input() generatorId: number;
    @Input() lockOrder: boolean;
    @Input() requiredError: boolean;
    @Input() efficiency: ProductEfficiency;
    @Input() efficiencySecondHeater: ProductEfficiency;
    @Output() removeGenerator = new EventEmitter<number>();
    @Output() moveGeneratorUp = new EventEmitter<number>();
    @Output() editQuantity = new EventEmitter<ProductElement>();
    @Output() editRoomHookupForControlUnitAccessory = new EventEmitter<ProductElement>();

    constructor(private translationService: TranslateService) {}

    getElementName(heatGeneratorElement: HeatGeneratorElement) {
        const element = heatGeneratorElement.productElement;
        if (!heatGeneratorElement.quantity && heatGeneratorElement.roomHookup == null) {
            if (HEAT_GENERATOR_IDS.includes(element.type.id)) {
                const generatorId = this.getEffectiveHeatGeneratorId(element);
                return `table.heat_generator_${generatorId}`;
            } else if (BURNER_IDS.includes(element.type.id)) {
                return 'table.burner';
            } else if (CONTROL_UNIT_IDS.includes(element.type.id)) {
                return 'table.control_unit';
            } else if (DHW_CYLINDER_IDS.includes(element.type.id)) {
                return 'table.dhw_cylinder_heating';
            } else if (HEATING_WATER_BUFFER_CYLINDER_IDS.includes(element.type.id)) {
                return 'table.heating_water_buffer_cylinder';
            } else if (CONTROL_UNIT_ACCESSORIES_IDS.includes(element.type.id)) {
                return 'table.control_unit_accessories';
            } else if (HEATING_WATER_BUFFER_CYLINDER_WITH_DHW_HEATING_IDS.includes(element.type.id)) {
                return 'table.heating_water_buffer_cylinder_with_dhw';
            }
        }
        return '';
    }

    private getEffectiveHeatGeneratorId(element: ProductElement) {
        // if there is a heat pump in the package, HP is first heat generator and all other ones are the second
        const hasHeatPump = this.heatGenerator.elements.some((e) => HEAT_PUMPS.includes(e.productElement.type.id));
        const generatorId = hasHeatPump ? (HEAT_PUMPS.includes(element.type.id) ? 1 : 2) : this.generatorId;
        return Math.max(generatorId, this.generatorId); // make sure we never return 1 when this.generatorId = 2
    }

    getElementId(heatGeneratorElement: HeatGeneratorElement) {
        if (!heatGeneratorElement.quantity && heatGeneratorElement.roomHookup == null) {
            return heatGeneratorElement.productElement.id;
        }
        return '';
    }

    getElementDescription(heatGeneratorElement: HeatGeneratorElement) {
        let roomHookupDescription = null;

        if (heatGeneratorElement.roomHookup != null) {
            const roomHookupDescriptionKey = heatGeneratorElement.roomHookup
                ? 'table.with_room_hookup'
                : 'table.without_room_hookup';
            roomHookupDescription = this.translationService.instant(roomHookupDescriptionKey);
        }

        return (
            heatGeneratorElement.quantity ??
            roomHookupDescription ??
            heatGeneratorElement.productElement.shortDescription ??
            ''
        );
    }

    getHeatingEfficiency(heatGeneratorElement: HeatGeneratorElement) {
        const element = heatGeneratorElement.productElement;
        if (!heatGeneratorElement.quantity && heatGeneratorElement.roomHookup == null) {
            if (HEAT_GENERATOR_IDS.includes(element.type.id) && this.isFirst(HEAT_GENERATOR_IDS, element)) {
                return this.efficiency.heating
                    ? `${this.getSign(this.efficiency.heating || '', true)}${Number.parseFloat(
                          this.efficiency.heating,
                      )} %`
                    : '';
            } else if (CONTROL_UNIT_IDS.includes(element.type.id)) {
                return this.efficiency.controlUnit?.heating
                    ? `${this.getSign(this.efficiency.controlUnit?.heating)}${Number.parseFloat(
                          this.efficiency.controlUnit?.heating,
                      )} %`
                    : '';
            } else if (element.config.primary === false) {
                return this.efficiencySecondHeater.heating
                    ? `${this.getSign(this.efficiencySecondHeater.heating || '', true)}${Number.parseFloat(
                          this.efficiencySecondHeater.heating,
                      )} %`
                    : '';
            }
        }

        return '';
    }

    getSign(efficiencyNumberRepresentation: string, hidePlus = false) {
        return efficiencyNumberRepresentation.includes('-') ? '- ' : hidePlus ? '' : '+ ';
    }

    isFirst(ids: string[], element: ProductElement) {
        return element === this.heatGenerator.generator.elements.find((e) => ids.includes(e.type.id));
    }

    getHotWaterEfficiency(heatGeneratorElement: HeatGeneratorElement) {
        const element = heatGeneratorElement.productElement;
        if (!heatGeneratorElement.quantity && heatGeneratorElement.roomHookup == null) {
            if (HEAT_GENERATOR_IDS.includes(element.type.id) && this.isFirst(HEAT_GENERATOR_IDS, element)) {
                return this.efficiency.water ? `${Number.parseFloat(this.efficiency.water)} %` : '';
            } else if (CONTROL_UNIT_IDS.includes(element.type.id)) {
                return this.efficiency.controlUnit?.water
                    ? `${Number.parseFloat(this.efficiency.controlUnit?.water)} %`
                    : '';
            }
        }

        return '';
    }
}
