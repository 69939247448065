import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { BackendService, Order, Product } from '../../../services/backend.service';
import { UserDataService } from '../../../services/user-data.service';

@Component({
    selector: 'app-product-search',
    templateUrl: './product-search.component.html',
    styleUrls: ['./product-search.component.scss'],
})
export class ProductSearchComponent {
    stateCtrl = new FormControl<Order | string>('');
    emptyOrder: Order = { id: '', longDescription: '', shortDescription: '', picture: '' };
    orders: Observable<Order[]>;
    toHighlight = '';
    language = 'de';
    vkorg = '0500';
    displaySalesReceiptResults = false;
    @Output() selectedProduct = new EventEmitter<Product>();

    constructor(
        private backend: BackendService,
        private userData: UserDataService,
        private snackBar: MatSnackBar,
        private translationService: TranslateService,
    ) {
        this.userData.language$.subscribe((val) => (this.language = val));
        this.userData.catalog$.subscribe((val) => (this.vkorg = val));
        this.orders = this.stateCtrl.valueChanges.pipe(
            startWith([]),
            debounceTime(400),
            distinctUntilChanged(),
            switchMap((val) => {
                if (val && typeof val === 'string' && val.length >= 3) {
                    return this.filter(val);
                } else {
                    return of([]);
                }
            }),
        );
        this.stateCtrl.valueChanges.subscribe((order) => {
            if (order && typeof order === 'object') {
                this.toHighlight = '';
                this.stateCtrl.setValue('');
                this.addProduct(order.id);
            } else if (typeof order === 'string') {
                this.toHighlight = order;
            }
        });
    }

    addProduct(orderId: string) {
        return this.backend
            .getProductDetails(orderId, this.vkorg, this.language)
            .pipe(
                catchError(() => {
                    this.snackBar.open(
                        this.translationService.instant('errors.backend'),
                        this.translationService.instant('errors.ok'),
                    );
                    return of(null);
                }),
            )
            .subscribe((product) => {
                if (!product) {
                    return;
                } else {
                    this.selectedProduct.emit(product);
                }
            });
    }

    filter(val: string): Observable<Order[]> {
        return this.backend.getProductSearch(this.vkorg, this.language, val).pipe(
            map((response) => {
                return response.items?.length >= 0 ? response.items : [];
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status !== 404) {
                    this.snackBar.open(
                        this.translationService.instant('errors.backend'),
                        this.translationService.instant('errors.ok'),
                    );
                }
                return [];
            }),
        );
    }

    displayFn(order: Order): string {
        return order && `${order.id} ${order.shortDescription}`;
    }
}
