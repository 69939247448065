<tr *ngIf="products?.length === 0" [class.error]="required">
    <th>{{ productName | translate }}</th>
    <td *ngIf="required">{{ 'table.required' | translate }}</td>
    <td *ngIf="!required">{{ 'table.optional' | translate }}</td>
    <td></td>
    <td class="optionButtons"></td>
    <td></td>
    <td class="heating"></td>
    <td class="hotWater"></td>
</tr>
<ng-container *ngFor="let product of products; let i = index">
    <tr>
        <th>{{ product.id }}</th>
        <td class="semi-bold-text" colspan="2">{{ product.longDescription }}</td>
        <td class="optionButtons"></td>
        <td class="deleteButton">
            <button
                class="table-button"
                (click)="
                    removeProduct.emit({
                        productArray: products,
                        index: i
                    })
                "
            >
                <mat-icon>close</mat-icon>
            </button>
        </td>
        <td class="heating"></td>
        <td class="hotWater"></td>
    </tr>
    <tr>
        <td class="elementName">{{ productName | translate }}</td>
        <td>{{ product.elements[0].id }}</td>
        <td>{{ product.elements[0].shortDescription }}</td>
        <td class="optionButtons">
            <app-download-documents-menu [element]="product.elements[0]"></app-download-documents-menu>
        </td>
        <td></td>
        <td class="heating"></td>
        <td class="hotWater"></td>
    </tr>
</ng-container>
