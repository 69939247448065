import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Product } from '../../../../services/backend.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-repeatable-product]',
    templateUrl: './repeatable-product.component.html',
    styleUrls: ['./repeatable-product.component.scss'],
})
export class RepeatableProductComponent {
    @Input() products: Product[];
    @Input() productName: string;
    @Input() required: boolean;
    @Output() removeProduct = new EventEmitter<{
        productArray: Product[];
        index: number;
    }>();
}
