import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { CustomProduct, EverestBackendService } from '../../../services/everest-backend.service';
import { PRODUCT_CATEGORIES } from '../products';

export interface SelectDialogData {
    mode: 'select' | 'admin';
}

@Component({
    selector: 'app-select-product',
    templateUrl: './select-product.component.html',
    styleUrls: ['./select-product.component.scss'],
})
export class SelectProductComponent implements OnInit {
    isLoading = false;
    isAdding = true;
    products: CustomProduct[] = [];
    isDeleting: string[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SelectDialogData,
        private dialogRef: MatDialogRef<SelectProductComponent>,
        private everestBackend: EverestBackendService,
        private errorHandlerService: ErrorHandlerService,
        private translationService: TranslateService,
    ) {}

    productCategories = PRODUCT_CATEGORIES.map((p) => {
        return {
            ...p,
            viewValue: this.translationService.instant(p.viewValue),
        };
    });

    getItemType(typeId: string) {
        return this.productCategories.find((p) => p.typeId === typeId)?.viewValue;
    }

    selectProduct(product: CustomProduct) {
        if (product._id) {
            this.isAdding = true;
            this.everestBackend
                .getProduct(product)
                .pipe(
                    catchError(() => {
                        this.errorHandlerService.handleGenericError();
                        return of(null);
                    }),
                )
                .subscribe((result) => {
                    if (!result) {
                        this.isAdding = false;
                    }
                    this.dialogRef.close(result);
                });
        }
    }

    deleteProduct(product: CustomProduct) {
        if (product._id) {
            this.isDeleting.push(product._id);
            this.everestBackend
                .deleteProduct(product)
                .pipe(
                    catchError(() => {
                        this.errorHandlerService.handleGenericError();
                        return of('error');
                    }),
                )
                .subscribe((result) => {
                    if (!result) {
                        const index = this.products.findIndex((p) => p._id === product._id);
                        this.products.splice(index, 1);
                    }
                    const deletingIndex = this.isDeleting.findIndex((id) => id === product._id);
                    this.isDeleting.splice(deletingIndex, 1);
                });
        }
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.everestBackend
            .getProducts()
            .pipe(catchError(this.errorHandlerService.handleGenericError))
            .subscribe((result) => {
                if (result) {
                    this.products = result;
                }
                this.isLoading = false;
            });
    }
}
