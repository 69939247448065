<table class="table">
    <tr>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th class="heatingHeader">
            {{ 'table.heating_header' | translate }}
            <div>
                <img class="tableIcon" src="assets/icon-heizung-schwarz.png" />
            </div>
        </th>
        <th class="hotWaterHeader">
            {{ 'table.hot_water_header' | translate }}
            <div>
                <img class="tableIcon" src="assets/icon-wasserhahn-schwarz.png" />
                {{ this.efficiencies.total?.w_scale }}
            </div>
        </th>
    </tr>
    <tbody
        app-heat-generator
        [heatGenerator]="heatGenerators[0]"
        [generatorId]="1"
        [requiredError]="missingElements.includes('boiler')"
        (removeGenerator)="removeGenerator($event)"
        [efficiency]="efficiencies.heatGenerator1"
        [efficiencySecondHeater]="efficiencies.heatGenerator2"
        (editQuantity)="editQuantity($event)"
        (editRoomHookupForControlUnitAccessory)="editRoomHookupForControlUnitAccessory($event)"
    ></tbody>

    <tbody>
        <tr *ngIf="missingElements.includes('controlUnit')" [class.error]="missingElements.includes('controlUnit')">
            <th>{{ 'table.control_unit' | translate }}</th>
            <td>{{ 'table.required' | translate }}</td>
            <td></td>
            <td class="optionButtons"></td>
            <td></td>
            <td class="heating"></td>
            <td class="hotWater"></td>
        </tr>
        <ng-container *ngIf="controlUnit">
            <tr>
                <th>{{ controlUnit.id }}</th>
                <td class="semi-bold-text" colspan="2">{{ controlUnit.longDescription }}</td>
                <td class="optionButtons"></td>
                <td class="deleteButton">
                    <button class="table-button" (click)="removeControlUnit()">
                        <mat-icon>close</mat-icon>
                    </button>
                </td>
                <td class="heating"></td>
                <td class="hotWater"></td>
            </tr>
            <tr>
                <td class="elementName">{{ 'table.control_unit' | translate }}</td>
                <td>{{ controlUnit.elements[0].id }}</td>
                <td>{{ controlUnit.elements[0].shortDescription }}</td>
                <td class="optionButtons"></td>
                <td></td>
                <td class="heating">{{ getControlUnitHeatingEfficiency() }}</td>
                <td class="hotWater">{{ getControlUnitHotWaterEfficiency() }}</td>
            </tr>
        </ng-container>
    </tbody>

    <tbody>
        <tr
            *ngIf="heatGenerators[0] && !controlUnitAccessories && !controlUnitAccessoryFromPackage"
            [class.error]="missingElements.includes('controlUnitAccessories')"
        >
            <th>{{ 'table.control_unit_accessories' | translate }}</th>
            <td *ngIf="missingElements.includes('controlUnitAccessories')">{{ 'table.required' | translate }}</td>
            <td *ngIf="!missingElements.includes('controlUnitAccessories')">{{ 'table.optional' | translate }}</td>
            <td></td>
            <td class="optionButtons"></td>
            <td></td>
            <td class="heating"></td>
            <td class="hotWater"></td>
        </tr>
        <ng-container *ngIf="controlUnitAccessories">
            <tr>
                <th>{{ controlUnitAccessories.id }}</th>
                <td class="semi-bold-text" colspan="2">{{ controlUnitAccessories.longDescription }}</td>
                <td class="optionButtons"></td>
                <td class="deleteButton">
                    <button class="table-button" (click)="removeControlUnitAccessories()">
                        <mat-icon>close</mat-icon>
                    </button>
                </td>
                <td class="heating"></td>
                <td class="hotWater"></td>
            </tr>
            <tr>
                <td class="elementName">{{ 'table.control_unit_accessories' | translate }}</td>
                <td>{{ controlUnitAccessories.elements[0].id }}</td>
                <td>{{ controlUnitAccessories.elements[0].shortDescription }}</td>
                <td class="optionButtons"></td>
                <td></td>
                <td class="heating"></td>
                <td class="hotWater"></td>
            </tr>
            <tr>
                <td class="elementName"></td>
                <td></td>
                <td>{{ controlUnitAccessories.elements[0].config.quantity }}</td>
                <td class="optionButtons">
                    <button class="table-button" (click)="editQuantity(controlUnitAccessories.elements[0])">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
                <td></td>
                <td class="heating"></td>
                <td class="hotWater"></td>
            </tr>
            <tr *ngIf="controlUnitAccessories.elements[0].config.room_hookup">
                <td class="elementName"></td>
                <td></td>
                <td>
                    {{
                        controlUnitAccessories.elements[0].config.room_hookup
                            ? ('table.with_room_hookup' | translate)
                            : ('table.without_room_hookup' | translate)
                    }}
                </td>
                <td class="optionButtons">
                    <button
                        class="table-button"
                        (click)="editRoomHookupForControlUnitAccessory(controlUnitAccessories.elements[0])"
                    >
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
                <td></td>
                <td class="heating"></td>
                <td class="hotWater"></td>
            </tr>
        </ng-container>
    </tbody>
    <tbody
        *ngIf="!containsSecondaryHeatGenerator"
        app-heat-generator
        [heatGenerator]="heatGenerators[1]"
        [generatorId]="2"
        (removeGenerator)="removeGenerator($event)"
        (moveGeneratorUp)="moveGeneratorUp($event)"
        [efficiency]="efficiencies.heatGenerator2"
        [lockOrder]="lockHeatersOrder || !!controlUnit"
        (editQuantity)="editQuantity($event)"
        (editRoomHookupForControlUnitAccessory)="editRoomHookupForControlUnitAccessory($event)"
    ></tbody>
    <tbody>
        <tr *ngIf="solarCollectors.length === 0" [class.error]="missingElements.includes('solarCollector')">
            <th>{{ 'table.solar_collectors' | translate }}</th>
            <td *ngIf="missingElements.includes('solarCollector')">{{ 'table.required' | translate }}</td>
            <td *ngIf="!missingElements.includes('solarCollector')">{{ 'table.optional' | translate }}</td>
            <td></td>
            <td class="optionButtons"></td>
            <td></td>
            <td class="heating"></td>
            <td class="hotWater"></td>
        </tr>
        <ng-container *ngFor="let collector of solarCollectors; let collectorIndex = index">
            <tr>
                <th>{{ collector.id }}</th>
                <td class="semi-bold-text" colspan="2">{{ collector.longDescription }}</td>
                <td class="optionButtons"></td>
                <td class="deleteButton">
                    <button
                        class="table-button"
                        (click)="removeProduct({ productArray: solarCollectors, index: collectorIndex })"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </td>
                <td class="heating"></td>
                <td class="hotWater"></td>
            </tr>
            <ng-container *ngFor="let collectorElement of collector.elements; let elementIndex = index">
                <tr>
                    <td class="elementName">{{ getElementName(collectorElement) | translate }}</td>
                    <td>{{ collectorElement.id }}</td>
                    <td>{{ collectorElement.shortDescription }}</td>
                    <td class="optionButtons">
                        <app-download-documents-menu [element]="collectorElement"></app-download-documents-menu>
                    </td>
                    <td></td>
                    <td class="heating">{{ getSolarHeatingEfficiency(collectorElement) }}</td>
                    <td class="hotWater">{{ getSolarHotWaterEfficiency(collectorElement) }}</td>
                </tr>
                <tr *ngIf="collectorElement.config.quantity">
                    <td class="elementName"></td>
                    <td></td>
                    <td>
                        {{
                            'table.solar_collectors_quantity'
                                | translate: { quantity: collectorElement.config.quantity }
                        }}
                    </td>
                    <td class="optionButtons">
                        <button class="table-button" (click)="editQuantity(collectorElement)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                    <td></td>
                    <td class="heating"></td>
                    <td class="hotWater"></td>
                </tr>
                <tr *ngIf="collectorIndex === 0 && elementIndex === 0">
                    <td class="elementName"></td>
                    <td></td>
                    <td>{{ getSolarUsageName(collectorElement.config.usage) | translate }}</td>
                    <td class="optionButtons">
                        <button class="table-button" (click)="editSolarUsage(collectorElement)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                    <td></td>
                    <td class="heating"></td>
                    <td class="hotWater"></td>
                </tr>
            </ng-container>
        </ng-container>
    </tbody>
    <tbody
        *ngIf="heatingWaterBufferCylindersWithDHWHeating.length > 0"
        app-repeatable-product
        (removeProduct)="removeProduct($event)"
        [products]="heatingWaterBufferCylindersWithDHWHeating"
        [required]="missingElements.includes('heatWaterBufferCylinderWithDHW')"
        productName="table.heating_water_buffer_cylinder_with_dhw"
    ></tbody>
    <tbody
        *ngIf="
            heatingWaterBufferCylinders.length > 0 ||
            (heatingWaterBufferCylindersWithDHWHeating.length === 0 &&
                !heatingWaterBufferCylindersWithDHWHeatingFromPackage)
        "
        app-repeatable-product
        (removeProduct)="removeProduct($event)"
        [products]="heatingWaterBufferCylinders"
        [required]="missingElements.includes('heatWaterBufferCylinder')"
        productName="table.heating_water_buffer_cylinder"
    ></tbody>
    <tbody
        *ngIf="
            dhwCylinders.length > 0 ||
            (heatingWaterBufferCylindersWithDHWHeating.length === 0 &&
                !heatingWaterBufferCylindersWithDHWHeatingFromPackage)
        "
        app-repeatable-product
        (removeProduct)="removeProduct($event)"
        [products]="dhwCylinders"
        [required]="missingElements.includes('dhwCylinder')"
        productName="table.dhw"
    ></tbody>
    <tbody
        *ngIf="solarCircuitDistributions.length > 0 || missingElements.includes('solarCircuitDistribution')"
        app-repeatable-product
        (removeProduct)="removeProduct($event)"
        [products]="solarCircuitDistributions"
        [required]="missingElements.includes('solarCircuitDistribution')"
        productName="table.solarCircuitDistribution"
    ></tbody>
    <tbody
        app-repeatable-product
        (removeProduct)="removeProduct($event)"
        [products]="domesticVentilations"
        [required]="missingElements.includes('domesticVentilation')"
        productName="table.ventilation"
    ></tbody>
    <tbody
        app-repeatable-product
        (removeProduct)="removeProduct($event)"
        [products]="electricWaterHeaters"
        [required]="missingElements.includes('electricWaterHeater')"
        productName="table.electric_water_heater"
    ></tbody>
    <tr class="efficiency" *ngIf="efficiencies.total?.w_amount || efficiencies.total?.h_amount">
        <td class="elementName"></td>
        <td></td>
        <td class="label" colspan="3">{{ 'table.current_efficiency' | translate }}</td>
        <td class="heating">
            {{ efficiencies.total?.h_amount ? efficiencies.total?.h_amount + ' %' : '' }}
            <div
                class="arrow"
                [ngClass]="getEfficiencyClass(efficiencies.total?.h_class)"
                *ngIf="efficiencies.total?.h_amount"
            >
                <span class="letter">{{ efficiencies.total?.h_class }}</span>
            </div>
        </td>
        <td class="hotWater">
            {{ efficiencies.total?.w_amount ? efficiencies.total?.w_amount + ' %' : '' }}
            <div
                class="arrow"
                [ngClass]="getEfficiencyClass(efficiencies.total?.w_class)"
                *ngIf="efficiencies.total?.w_amount"
            >
                <span class="letter">{{ efficiencies.total?.w_class }}</span>
            </div>
        </td>
    </tr>
    <tr
        class="efficiency next-efficiency"
        *ngIf="efficiencies.total?.w_next_efficiency || efficiencies.total?.h_next_efficiency"
    >
        <td class="elementName"></td>
        <td></td>
        <td class="label" colspan="3">
            <ng-container *ngIf="efficiencies.total?.h_class !== 'A+++' || efficiencies.total?.w_class !== 'A+++'">
            </ng-container>
            {{ 'table.next_efficiency' | translate }}
        </td>
        <td class="heating">
            <ng-container *ngIf="efficiencies.total?.h_class !== 'A+++'">
                {{ efficiencies.total?.h_next_efficiency ? '+' + efficiencies.total?.h_next_efficiency + ' %' : '' }}
                <div
                    class="arrow"
                    [ngClass]="getEfficiencyClass(efficiencies.total?.h_next_class)"
                    *ngIf="efficiencies.total?.h_next_efficiency"
                >
                    <span class="letter">{{ efficiencies.total?.h_next_class }}</span>
                </div>
            </ng-container>
        </td>
        <td class="hotWater">
            <ng-container *ngIf="efficiencies.total?.w_class !== 'A+++'">
                {{ efficiencies.total?.w_next_efficiency ? '+' + efficiencies.total?.w_next_efficiency + ' %' : '' }}
                <div
                    class="arrow"
                    [ngClass]="getEfficiencyClass(efficiencies.total?.w_next_class)"
                    *ngIf="efficiencies.total?.w_next_efficiency"
                >
                    <span class="letter">{{ efficiencies.total?.w_next_class }}</span>
                </div>
            </ng-container>
        </td>
    </tr>
</table>
